import styled from "styled-components";

export const Input = styled.input`
  min-width: 260px;
  height: 30px;
  font-size: 18px;
  font-family: inherit;
  border-radius: 4px;
  padding: 0.5rem 0.6rem;
  /* box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 15px; */
  border-color: #999999;
`;
