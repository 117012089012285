import React, { useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import API from "@aws-amplify/api";
import axios from "axios";
import { saveAs } from "file-saver";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Theme } from "../../config/aws-exports";
import { Box } from "@mui/system";
import AppBarDashboard from "../../components/AppBar/AppBar.Dashboard";
import { HeadingPrimary } from "../../components/Styles/Heading";
import { Avatar, Divider, Grid } from "@mui/material";
import { authData } from "../../redux/auth/authSlice";
import config from "../../config/aws-exports";
import { useSelector } from "react-redux";
import { Input } from "../../components/Styles/Input";
import { Container } from "../../components/Styles/Container";
import { Skeleton } from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogContent from "@mui/material/DialogContent";
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import { Backdrop, CircularProgress } from "@mui/material";
import { useQuery } from "react-query";
import logo from "../../assets/USP logo R.png";
import moment from "moment";
import emptysearch from "../../assets/Nothing here yet.gif";
import { makeStyles } from "@mui/styles";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "2rem",
  },
  header: {
    background: "#DED7CF",
    padding: "0.5rem 2rem",
    display: "flex",
    height: "3rem",
    justifyContent: "space-between",
    "& > h2": {
      margin: 0,
      color: "#E96138",
    },
    "& > div": {
      position: "relative",
      right: "-3rem",
      top: "0",
    },
  },
  body: {
    margin: "2rem 6rem",
    "& > h4": {
      textAlign: "center",
      textDecoration: "underline",
    },
  },
  table: {
    border: "3px solid",
    borderCollapse: "collapse",
    width: "100%",
    margin: "1rem 0rem",
    "& > tr > td": {
      border: "2px solid",
      padding: "0px 6px",
    },
    "& > tr > th": {
      border: "2px solid",
      textAlign: "left",
      padding: "0px 6px",
    },
  },
  footer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0.5rem 2rem 2rem",
    borderTop: "3px solid #B26253",
    "& > h3": {
      margin: 0,
    },
    fontSize: "18px",
  },
  alignRight: {
    textAlign: "right",
  },
  alignCenter: {
    textAlign: "center",
  },
}));

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

export default function Invoices() {
  const userDetails = useSelector(authData);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  console.log("userDe", userDetails);
  const classes = useStyles();

  const [invoiceOpen, setInvoiceOpen] = useState(false);
  const [viewingState, setViewingState] = useState({});
  const [invoiceOpenNew, setInvoiceOpenNew] = useState(false);
  const [viewingStateNew, setViewingStateNew] = useState({});
  var re = /(?:\.([^.]+))?$/;

  const { data, isLoading, error } = useQuery(["invoices"], async () => {
    try {
      const bodyParam = {
        body: {
          oid: config.aws_org_id,
          tenant: userDetails.tenant,
          eid: userDetails.username,
          ur_id: userDetails?.uData?.ur_id,
          schema: config.schema,
          type: "invoice",
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/getInvoices ",
        bodyParam
      );
      return response;
    } catch (error) {
      console.error(error);
    }
  });
  const breadcrumbs = [
    <Typography key="3" color={Theme.color.text}>
      Invoices
    </Typography>,
  ];

  const handleViewClick = (item) => {
    setViewingState(item);
    setInvoiceOpen(true);
  };
  const handleViewClickNew = (item) => {

    setViewingStateNew(item.invoice_dtls);
    setInvoiceOpenNew(true);
  };

  async function downloadcode() {
    const pdf = new jsPDF("portrait", "pt", "a4");
    const data = await html2canvas(document.querySelector("#invoicepdf"), {
      allowTaint: true,
      useCORS: true,
      logging: true,
    });
    const img = data.toDataURL("image/png");
    const imgProperties = pdf.getImageProperties(img);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save(
      viewingStateNew.sn?.length >= 90
        ? viewingStateNew.sn?.substring(0, 90)
        : viewingStateNew.sn + ".pdf"
    );
  }

  function downloadPdf() {
    let filename, type, durl;
    type = "image/png";
    filename = data?.invoices.invoiceid;
    durl = `https://${config.aws_cloudfront_url
      }/${config.aws_org_id.toLowerCase()}-resources/documents/invoices/${viewingState.invoiceid
      }`;

    var ext = re.exec(viewingState.invoiceid)[1];
    if (ext !== undefined && ext === "pdf") {
      type = "application/pdf";
    }
    axios
      .get(durl, {
        responseType: "blob",
        method: "GET",
        headers: {
          "Content-Type": type,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET",
        },
      })
      .then((res) => {
        let file = new Blob([res.data], { type: type });
        saveAs(file, filename);
      });
  }

  var a = [
    "",
    "one ",
    "two ",
    "three ",
    "four ",
    "five ",
    "six ",
    "seven ",
    "eight ",
    "nine ",
    "ten ",
    "eleven ",
    "twelve ",
    "thirteen ",
    "fourteen ",
    "fifteen ",
    "sixteen ",
    "seventeen ",
    "eighteen ",
    "nineteen ",
  ];
  var b = [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];

  function numberToWordsOnly(number) {
    const ones = [
      "",
      "one",
      "two",
      "three",
      "four",
      "five",
      "six",
      "seven",
      "eight",
      "nine",
    ];
    const tens = [
      "",
      "",
      "twenty",
      "thirty",
      "forty",
      "fifty",
      "sixty",
      "seventy",
      "eighty",
      "ninety",
    ];
    const scales = ["", "thousand", "million", "billion", "trillion"];

    if (number === 0) return "zero only";

    const sign = number < 0 ? "negative " : "";
    let absoluteNumber = Math.abs(number);

    let scaleCount = 0;
    let words = "";

    while (absoluteNumber > 0) {
      const hundred = absoluteNumber % 1000;
      if (hundred > 0) {
        let hundredWords = "";
        if (hundred < 10) hundredWords = ones[hundred];
        else if (hundred < 20) hundredWords = ones[hundred % 10] + "teen";
        else if (hundred < 100)
          hundredWords =
            tens[Math.floor(hundred / 10)] + " " + ones[hundred % 10];
        else
          hundredWords =
            ones[Math.floor(hundred / 100)] +
            " hundred " +
            tens[Math.floor((hundred % 100) / 10)] +
            " " +
            ones[hundred % 10];
        words = hundredWords + " " + scales[scaleCount] + " " + words;
      }
      absoluteNumber = Math.floor(absoluteNumber / 1000);
      scaleCount++;
    }

    return sign + words.trim() + " only";
  }

  const sum = (var1 = 0, var2 = 0) => {
    return var1 + var2;
  };

  if (error)
    return (
      <Container m={true}>
        <AppBarDashboard />
        <Stack spacing={2} margin="3rem" paddingBottom="3rem">
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
            sx={{ color: Theme.color.black }}
          >
            {breadcrumbs}
          </Breadcrumbs>
          <h3>Proforma invoices</h3>
          <h3>{error.message}</h3>
        </Stack>
      </Container>
    );

  return (
    <>
      <Stack spacing={2} margin="3rem" paddingBottom="3rem">
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          sx={{ color: Theme.color.black }}
        >
          {breadcrumbs}
        </Breadcrumbs>
        <Stack spacing={2} margin={{ xs: 1, sm: 2, md: 3 }}>
          <h3>Proforma invoices</h3>
          <Grid container spacing={{ xs: 1, sm: 2 }}>
            {data?.invoices.newInv.map((item, idx) => (
              <Grid item onClick={() => handleViewClickNew(item)}>
                <>
                  <Box
                    sx={{
                      width: "260px",
                      borderRadius: "8px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "1px solid #d2d2d2",
                      cursor: "pointer",
                    }}
                  >
                    <PictureAsPdfIcon
                      style={{
                        fill: "red",
                        fontSize: "8rem",
                        height: "30px",
                        width: "50px",
                      }}
                    />
                    <LightTooltip
                      title={item.invoice_dtls?.sn}
                      placement="top-start"
                    >
                      <Box component="p" sx={{ mt: 1, fontSize: "17.5px" }}>
                        {item.invoice_dtls?.sn.substring(0, 15)}{" "}
                        {item.invoice_dtls.sn.length > 15 ? "..." : ""} &
                        <Box component="span" sx={{ ml: 0.3 }}>
                          {moment(
                            new Date(item.invoice_dtls.sessionDate).getTime()
                          ).format("DD MMM YYYY")}
                        </Box>
                      </Box>
                    </LightTooltip>
                  </Box>
                </>
              </Grid>
            ))}
            {data?.invoices?.oldInv.map((item, idx) => (
              <Grid item onClick={() => handleViewClick(item)}>
                {re.exec(item.invoiceid)[1] !== undefined &&
                  re.exec(item.invoiceid)[1] === "pdf" ? (
                  <>
                    <Box
                      sx={{
                        width: "260px",
                        borderRadius: "8px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        border: "1px solid #d2d2d2",
                        cursor: "pointer",
                      }}
                    >
                      <PictureAsPdfIcon
                        style={{
                          fill: "red",
                          fontSize: "8rem",
                          height: "30px",
                          width: "50px",
                        }}
                      />
                      <LightTooltip title={item.sn} placement="top-start">
                        <Box component="p" sx={{ mt: 1, fontSize: "17.5px" }}>
                          {item.sn.substring(0, 15)}{" "}
                          {item.sn.length > 15 ? "..." : ""} &
                          <Box component="span" sx={{ ml: 0.3 }}>
                            {item.date_list !== undefined &&
                              moment(
                                new Date(
                                  item.date_list[0].combineStartTime
                                ).getTime()
                              ).format("DD MMM YYYY")}
                          </Box>
                        </Box>
                      </LightTooltip>
                    </Box>
                  </>
                ) : (
                  <Box
                    sx={{
                      width: "260px",
                      borderRadius: "8px",
                      cursor: "pointer",
                    }}
                  >
                    <img
                      style={{
                        width: "250px",
                        height: "180px",
                        borderRadius: "10px",
                      }}
                      src={`https://${config.aws_cloudfront_url
                        }/${config.aws_org_id.toLowerCase()}-resources/documents/invoices/${item.invoiceid
                        }`}
                      alt=""
                    />
                    <Box component="p" sx={{ mt: 1, fontSize: "17.5px" }}>
                      {item.sn.substring(0, 15)}{" "}
                      {item.sn.length > 15 ? "..." : ""} &
                      <Box component="span" sx={{ ml: 0.3 }}>
                        {item.date_list
                          ? moment(
                            new Date(
                              item.date_list[0].combineStartTime
                            ).getTime()
                          ).format("DD MMM YYYY")
                          : item.startdate
                            ? moment(parseInt(item.startdate)).format(
                              "DD MMM YYYY"
                            )
                            : "yet to be scheduled"}
                      </Box>
                    </Box>
                  </Box>
                )}
              </Grid>
            ))}
          </Grid>
          {data?.invoices?.length === 0 && (
            <div
              style={{
                height: "60vh",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <img src={emptysearch} alt="emptysearch" />
              {/* <h3>Nothing here yet!</h3> */}
            </div>
          )}
        </Stack>
      </Stack>
      <Dialog
        fullScreen={fullScreen}
        open={invoiceOpenNew}
        aria-labelledby="responsive-dialog-title-2"
        maxWidth="lg"
      >
        <DialogTitle id="responsive-dialog-title">
          {/* <h5>Invoice - {viewingStateNew.sn}</h5> */}
          <IconButton
            onClick={() => {
              setInvoiceOpenNew(false);
            }}
            sx={{ position: "absolute", right: 10, top: 10 }}
          >
            <Close sx={{ fontSize: "2rem" }} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {viewingStateNew.invoiceid === undefined ||
            viewingStateNew.invoiceid === null ? (
            <div className={classes.container} id="invoicepdf">
              <div className={classes.header}>
                <h2>USP Education</h2>
                <div>
                  <img src={logo} alt="logo" height="80" />
                </div>
              </div>
              <div className={classes.body}>
                <p>
                  {moment(
                    new Date(viewingStateNew.date).getTime()
                  ).format("DD MMMM YYYY")}</p>
                <br />
                <h4>Proforma Invoice</h4><br />
                <span>
                  Bill to: -<br />
                  <span style={{ marginLeft: "1rem", fontWeight: "bold" }}>
                    {viewingStateNew.address}
                  </span>
                  <br />
                  <br />
                  <span>GST: {viewingStateNew.gstNo}</span>
                </span>
                <p style={{ margin: "2rem 0rem" }}>
                  Payment Terms: 100% Advance Payment
                </p>
                <div>
                  <TableContainer component={Paper}>
                    <Table
                      sx={{ minWidth: 650 }}
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableHead>
                        <TableRow sx={{ "th, td": { border: 1.5 } }}>
                          <TableCell>Line</TableCell>
                          <TableCell>Description</TableCell>
                          <TableCell>HSN</TableCell>
                          <TableCell>Qty.</TableCell>

                          <TableCell>{`Unit(${viewingStateNew.unit})`}</TableCell>
                          <TableCell>{`Amount(${viewingStateNew.unit})`}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow
                          //sx={{ '&:last-child td, &:last-child th, &:second-child td': { border: 1 } }}
                          sx={{ "th, td": { border: 1.5 } }}
                        >
                          <TableCell component="th" scope="row" align="center">
                            1
                          </TableCell>
                          <TableCell>{`${viewingStateNew.sn} on ${viewingStateNew.sessionDate}`}</TableCell>
                          <TableCell align="center">999293</TableCell>
                          <TableCell align="center">1</TableCell>
                          <TableCell align="center">
                            {viewingStateNew.price}
                          </TableCell>
                          <TableCell align="right">
                            {viewingStateNew.price}
                          </TableCell>
                        </TableRow>
                        <TableRow
                          //sx={{ '&:last-child td, &:last-child th, &:second-child td': { border: 1 } }}
                          sx={{ "th, td": { border: 1.5 } }}
                        >
                          <TableCell component="th" scope="row" align="center">
                            2
                          </TableCell>
                          <TableCell>Discount</TableCell>
                          <TableCell align="center"></TableCell>
                          <TableCell align="center"></TableCell>
                          <TableCell align="center"></TableCell>
                          <TableCell align="right">
                            {viewingStateNew.discount}
                          </TableCell>
                        </TableRow>
                        <TableRow
                          //sx={{ '&:last-child td, &:last-child th, &:second-child td': { border: 1 } }}
                          sx={{ "th, td": { border: 1.5 } }}
                        >
                          <TableCell component="th" scope="row" align="center">
                            3
                          </TableCell>
                          <TableCell>IGST @ 18%</TableCell>
                          <TableCell align="center"></TableCell>
                          <TableCell align="center"></TableCell>
                          <TableCell align="center"></TableCell>
                          <TableCell align="right">
                            {viewingStateNew.gst}
                          </TableCell>
                        </TableRow>
                        <TableRow
                          //sx={{ '&:last-child td, &:last-child th, &:second-child td': { border: 1 } }}
                          sx={{ "th, td": { border: 1.5 } }}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            align="center"
                          ></TableCell>
                          <TableCell></TableCell>
                          <TableCell align="center"></TableCell>
                          <TableCell align="center"></TableCell>
                          <TableCell align="center"></TableCell>
                          <TableCell align="right">
                            {viewingStateNew.totalAmount}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
                <div>
                  <p style={{ margin: "1rem 0rem", fontWeight: "700" }}>
                    {viewingStateNew.unit}{" "}
                    {numberToWordsOnly(
                      sum(+viewingStateNew?.price, +viewingStateNew?.gst)
                    )}
                  </p>
                  <table>
                    <tr>
                      <td>USP India GSTIN</td>
                      <td>: 36AAACU7542C1ZI</td>
                    </tr>
                    <tr>
                      <td>USP India PAN No</td>
                      <td>: AAACU7542C</td>
                    </tr>
                  </table>

                  <p style={{ margin: "25px 0px" }}>
                    Nature of services: "Commercial Training and Coaching
                    Services".
                    <br />
                    Please inform us in advance if your unit is in SEZ for GST
                    amount.
                  </p>

                  <p>
                    Please Remit to:
                    <br />
                    Beneficiary: "United States Pharmacopeia India (P) Ltd."
                    <br />
                    Bank: ICICI Bank Ltd
                    <br />
                    Address: 6-2-1012, TGV Mansion, Khairtabad, Hyderabad.
                    <br />
                    Account: 000805005833
                    <br />
                    IFSC Code: ICIC0000008
                    <br />
                    Swift Code: ICICINBB008
                    <br />
                    Note: Taxes are subject to changes as per Government rules.
                  </p>

                  <h4 style={{ textAlign: "center", margin: "3rem 0rem" }}>
                    This is an electronic document hence signature is not
                    required
                  </h4>
                </div>
              </div>

              <div className={classes.footer}>
                <h3>education.usp.org</h3>
                <h3>Empowering a healthy tomorrow</h3>
              </div>
              {/*  {open && <ProformaEdit handleClose={handleClose} open={open} />} */}
            </div>
          ) : (
            <div>
              {re.exec(viewingState.invoiceid)[1] !== undefined &&
                re.exec(viewingState.invoiceid)[1] === "pdf" ? (
                <>
                  <iframe
                    src={`https://${config.aws_cloudfront_url
                      }/${config.aws_org_id.toLowerCase()}-resources/documents/invoices/${viewingState.invoiceid
                      }`}
                    height="500"
                    width="750"
                  />
                </>
              ) : (
                <img
                  src={`https://${config.aws_cloudfront_url
                    }/${config.aws_org_id.toLowerCase()}-resources/documents/invoices/${viewingState.invoiceid
                    }`}
                  alt={viewingState.invoiceid}
                  height="500"
                  width="750"
                />
              )}
            </div>
          )}
        </DialogContent>

        <DialogActions
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            sx={{
              color: Theme.color.primary,
              borderColor: Theme.color.primary,
              textTransform: "unset",
              "&:hover": {
                color: Theme.color.primary,
                borderColor: Theme.color.primary,
                textTransform: "unset",
              },
            }}
            variant="outlined"
            onClick={() => downloadcode()}
          >
            Download
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullScreen={fullScreen}
        open={invoiceOpen}
        aria-labelledby="responsive-dialog-title-2"
        maxWidth="md"
      >
        <DialogTitle id="responsive-dialog-title">
          <h5>Invoice - {viewingState.sn}</h5>
          <IconButton
            onClick={() => {
              setInvoiceOpen(false);
            }}
            sx={{ position: "absolute", right: 10, top: 10 }}
          >
            <Close sx={{ fontSize: "2rem" }} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {re.exec(viewingState.invoiceid)[1] !== undefined &&
            re.exec(viewingState.invoiceid)[1] === "pdf" ? (
            <>
              <iframe
                src={`https://${config.aws_cloudfront_url
                  }/${config.aws_org_id.toLowerCase()}-resources/documents/invoices/${viewingState.invoiceid
                  }`}
                height="500"
                width="750"
              />
            </>
          ) : (
            <img
              src={`https://${config.aws_cloudfront_url
                }/${config.aws_org_id.toLowerCase()}-resources/documents/invoices/${viewingState.invoiceid
                }`}
              alt={viewingState.invoiceid}
              height="500"
              width="750"
            />
          )}
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            sx={{
              color: Theme.color.primary,
              borderColor: Theme.color.primary,
              textTransform: "unset",
              "&:hover": {
                color: Theme.color.primary,
                borderColor: Theme.color.primary,
                textTransform: "unset",
              },
            }}
            variant="outlined"
            onClick={() => downloadPdf()}
          >
            Download
          </Button>
        </DialogActions>
      </Dialog>

      <Backdrop open={isLoading} sx={{ zIndex: "theme.drawer" + 1 }}>
        <CircularProgress />
      </Backdrop>
    </>
  );
}
