import React from "react";
import ReactDOM from "react-dom";
import Amplify from "aws-amplify";
import App from "./App";
import { PersistGate } from "redux-persist/integration/react";

import { store, persister } from "./redux/store";
import { Provider } from "react-redux";
import awsExports from "./config/aws-exports";

import { QueryClient, QueryClientProvider } from "react-query";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import "tippy.js/animations/shift-away-subtle.css";
Amplify.configure(awsExports);

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persister}>
          <App />
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
