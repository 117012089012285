import React, { useState } from "react";

import { DialogContent, DialogTitle, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";

import { API } from "aws-amplify";
import data from "./feedback.json";
import config from "../../../config/aws-exports";
import FeedbackItem from "./FeedbackItem";

function USPFeedback({ handleClose, sid, type, ur_id }) {
  // State to store the answers
  const [answers, setAnswers] = useState([]);

  const handleMultipleRadioChange = (questionNumber, sqi, option, type) => {
    const find = answers.find(
      (ans) => ans.question === questionNumber && ans.subQuestion === sqi + 1
    );
    const newAnswer = {
      type,
      question: questionNumber,
      subQuestion: sqi + 1,
      answer: option,
    };
    console.log(find);
    if (!find) {
      setAnswers((prevAnswers) => [...prevAnswers, newAnswer]);
    } else {
      setAnswers((prevAnswers) => {
        return prevAnswers.map((ans) =>
          ans.question === questionNumber && ans.subQuestion === sqi + 1
            ? { ...ans, answer: option }
            : ans
        );
      });
    }
  };
  const handleRadioChange = (questionNumber, option, type) => {
    const find = answers.find((ans) => ans.question === questionNumber);
    const newAnswer = {
      type,
      question: questionNumber,
      answer: option,
    };
    console.log(find);
    if (!find) {
      setAnswers((prevAnswers) => [...prevAnswers, newAnswer]);
    } else {
      setAnswers((prevAnswers) => {
        return prevAnswers.map((ans) =>
          ans.question === questionNumber ? { ...ans, answer: option } : ans
        );
      });
    }
  };

  const handleTextChange = (questionNumber, value, type) => {
    const find = answers.find((ans) => ans.question === questionNumber);
    const newAnswer = {
      type,
      question: questionNumber,
      answer: value,
    };

    if (!find) {
      setAnswers((prevAnswers) => [...prevAnswers, newAnswer]);
    } else {
      setAnswers((prevAnswers) => {
        return prevAnswers.map((ans) =>
          ans.question === questionNumber ? { ...ans, answer: value } : ans
        );
      });
    }
  };
  const handleSubmit = async () => {
    const bodyParam = {
      body: {
        feedbacklist: answers,
        sid,
        type,
        ur_id,
      },
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/feedback",
        bodyParam
      );
      return response;
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <DialogTitle id="responsive-dialog-title" sx={{ position: "relative" }}>
        {"Please provide your feedback"}
        <IconButton
          onClick={() => {
            handleClose();
          }}
          sx={{ position: "absolute", right: 10, top: 10 }}
        >
          <Close sx={{ fontSize: "2rem" }} />
        </IconButton>
      </DialogTitle>
      <DialogContent
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        {data[type]["feedback-questions"].map((questions, fqi) => {
          return (
            <FeedbackItem
              {...questions}
              fqi={fqi}
              handleMultipleRadioChange={handleMultipleRadioChange}
              handleRadioChange={handleRadioChange}
              handleTextChange={handleTextChange}
            />
          );
        })}
      </DialogContent>
      {/* Do something with the answers, for example, send them to a server */}
      <button onClick={handleSubmit}>Submit Feedback</button>
    </>
  );
}

export default USPFeedback;
