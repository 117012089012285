import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: {},
};

export const sessionViewAll = createSlice({
  name: "session",
  initialState,
  reducers: {
    sessionAll: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { sessionAll } = sessionViewAll.actions;

export default sessionViewAll.reducer;
