import { combineReducers } from "redux";
import authReducer from "./auth/authSlice";
import loginReducer from "./auth/loginSlice";

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import expressInterest from "./ExpressInterest/ExpressInterest";
import sessionData from "./Session/Session";
import RegFormStatus from "./RegFormStatus/RegFormStatus";
import sessionViewAll from "./viewAll/viewAll";

const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    "auth",
    "login",
    "sessionData",
    "interests",
    "regStatus",
    "sessionViewAll",
  ],
};
const rootReducer = combineReducers({
  auth: authReducer,
  login: loginReducer,
  interests: expressInterest,
  sessionData,
  sessionViewAll,
  regStatus: RegFormStatus,
});

export default persistReducer(persistConfig, rootReducer);
