import React, { useState, useEffect } from "react";

import { DialogContent, DialogTitle, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";

import { API } from "aws-amplify";
import data from "./coursefeedbacklist.json";
import config, { Theme } from "../../config/aws-exports";
import FeedbackItem from "../LiveSession/FeedbackForm/FeedbackItem";
import { LoadingButton } from "@mui/lab";
import Swal from "sweetalert2";

function CourseFeedback({ handleFeedbackClose, handleClose, sid, type, ur_id, setIsLoading, isLoading, refetch }) {
  // State to store the answers
  const [answers, setAnswers] = useState([]);
  const [errors, setErrors] = useState(null);
  const [showSubmit, setShowSubmit] = useState(false);
  useEffect(() => {

    getFeedbackDetails();
  }, []);

  async function getFeedbackDetails() {
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        sid: sid,
        ur_id: ur_id,
        schema: config.schema,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/getFeedback",
        bodyParam
      );
      console.log(response);
      if (response?.body != undefined && response?.body?.length > 0) {
        setAnswers(response.body);
        setShowSubmit(true);
      }
      setIsLoading(false);
    } catch (err) {
      console.error(err);
    }
  }

  const handleMultipleRadioChange = (questionNumber, sqi, option, type) => {

    const find = answers.find(
      (ans) => ans.question === questionNumber && ans.subQuestion === sqi + 1
    );
    const newAnswer = {
      type,
      question: questionNumber,
      subQuestion: sqi + 1,
      answer: option,
    };
    console.log(find);
    if (!find) {
      setAnswers((prevAnswers) => [...prevAnswers, newAnswer]);
    } else {
      setAnswers((prevAnswers) => {
        return prevAnswers.map((ans) =>
          ans.question === questionNumber && ans.subQuestion === sqi + 1
            ? { ...ans, answer: option }
            : ans
        );
      });
    }
  };
  const handleRadioChange = (questionNumber, option, type) => {
    const find = answers.find((ans) => ans.question === questionNumber);
    const newAnswer = {
      type,
      question: questionNumber,
      answer: option,
    };
    console.log(find);
    if (!find) {
      setAnswers((prevAnswers) => [...prevAnswers, newAnswer]);
    } else {
      setAnswers((prevAnswers) => {
        return prevAnswers.map((ans) =>
          ans.question === questionNumber ? { ...ans, answer: option } : ans
        );
      });
    }
  };

  const handleTextChange = (questionNumber, value, type) => {
    const find = answers.find((ans) => ans.question === questionNumber);
    const newAnswer = {
      type,
      question: questionNumber,
      answer: value,
    };

    if (!find) {
      setAnswers((prevAnswers) => [...prevAnswers, newAnswer]);
    } else {
      setAnswers((prevAnswers) => {
        return prevAnswers.map((ans) =>
          ans.question === questionNumber ? { ...ans, answer: value } : ans
        );
      });
    }
  };
  const handleSubmit = async () => {
    const tt = await validateAnswers(answers);
   
    if (!tt) {
      setIsLoading(true);
      const bodyParam = {
        body: {
          feedbacklist: answers,
          sid,
          type,
          ur_id,
        },
        headers: {
          "Content-Type": "application/json",
        },
      };

      try {
        await API.post(
          config.aws_cloud_logic_custom_name,
          "/feedback",
          bodyParam
        );
        setIsLoading(false);
        Swal.fire({ title: "Success", text: 'Successfully Submitted Your Feedback', icon: 'success' }).then((willDelete) => {
          if (willDelete) {
            handleFeedbackClose();
            handleClose();
            refetch();
          }
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  async function validateAnswers(answers) {
    const requiredSubQuestions = {
      1: [1, 2, 3, 4, 5, 6],
      6: [1, 2, 3, 4, 5, 6],
      8: [1, 2, 3],
      10: [1]
    };

    const requiredQuestions = [3, 5, 12, 13, 14];

    // Validate sub-questions
    for (let question in requiredSubQuestions) {
      let subQuestions = requiredSubQuestions[question];
      let answeredSubQuestions = answers
        .filter(answer => answer.question == question)
        .map(answer => answer.subQuestion)
        .filter(subQuestion => subQuestion !== undefined);

      for (let subQuestion of subQuestions) {
        if (!answeredSubQuestions.includes(subQuestion)) {
          setErrors('Please fill all the fields');
          return true;
        }
      }
    }

    // Validate required questions
    for (let question of requiredQuestions) {
      let answer = answers.find(answer => answer.question == question);
      if (!answer || answer.answer === null || answer.answer === undefined || answer.answer === '') {
        setErrors('Please fill all the fields');
        return true;
      }
    }

  }
  return (
    <>
      <DialogTitle id="responsive-dialog-title" sx={{ position: "relative" }}>
        {"Please provide your feedback"}
        <IconButton
          onClick={() => {
            handleFeedbackClose();
            handleClose();
          }}
          sx={{ position: "absolute", right: 10, top: 10 }}
        >
          <Close sx={{ fontSize: "2rem" }} />
        </IconButton>
      </DialogTitle>
      <DialogContent
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        {data[type]["feedback-questions"].map((questions, fqi) => {
          return (
            <FeedbackItem
              {...questions}
              fqi={fqi}
              handleMultipleRadioChange={handleMultipleRadioChange}
              handleRadioChange={handleRadioChange}
              handleTextChange={handleTextChange}
            />
          );
        })}
      </DialogContent>
      {/* Do something with the answers, for example, send them to a server */}
     
      {!showSubmit && <div style={{ textAlign: 'center' }}>
        <p style={{ color: 'red' }}>{errors}</p>
        <LoadingButton
          variant="contained"
          sx={{
            backgroundColor: Theme.color.secondary,
            color: Theme.color.white,
          }}
          style={{ marginTop: '10px', marginBottom: '10px' }}
          loading={isLoading}
          onClick={handleSubmit}
          autoFocus
        >
          Submit Feedback
        </LoadingButton>
      </div>}
    </>
  );
}

export default CourseFeedback;
