import {
  HashRouter as Router,
  Switch,
  Route,
  useLocation,
  Redirect,
} from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { Theme, GlobalStyle } from "./config/aws-exports";
import About from "./pages/About/About";
import Dashboard from "./pages/Dashboard/Dashboard";
import Home from "./pages/Home/Home";
import LiveSession from "./pages/LiveSession/LiveSession";
import Profile from "./pages/Profile/Profile";
import Calendar from "./pages/Calendar/Calendar";
import Notifications from "./pages/Notifications/Notifications";
import Course from "./pages/Course/Course";

import { useSelector } from "react-redux";
import { authData } from "./redux/auth/authSlice";
import Bookmark from "./pages/Bookmark/Bookmark";
import Wishlist from "./pages/WishList/Wishlist";
import Search from "./pages/Search/Search";
import ViewAllSession from "./pages/LiveSession/ViewAllSession/ViewAllSession";
import ViewAllCourses from "./pages/Course/ViewAllCourses/ViewAllCourses";
import Certificate from "./pages/Certificate/Certificate";
import Invoices from "./pages/Invoices/Invoices";
import PreLoginLiveSession from "./pages/PreLoginPage/LiveSession";
import ViewAll from "./pages/ViewAll/ViewAll";
import Help from "./pages/Help/Help";
import Layout from "./components/Layout/Layout";
import PreLoginCourse from "./pages/PreLoginCourse/PreLoginCourse";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
function NoMatch() {
  let location = useLocation();

  return (
    <div>
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    </div>
  );
}
const App = () => {
  const userDetails = useSelector(authData);

  return (
    <ThemeProvider theme={Theme}>
      <GlobalStyle />
      <Router>
        <ScrollToTop />
        {userDetails === 0 ? (
          <>
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              <Route path="/about">
                <About />
              </Route>
              <Route path="/prelogin">
                <PreLoginLiveSession />
              </Route>
              <Route path="/preCourse">
                <PreLoginCourse />
              </Route>
              <Route path="*">
                <Home />
              </Route>
            </Switch>
          </>
        ) : (
          <Layout>
            <Switch>
              <Route exact path="/">
                {userDetails !== 0 ? <Redirect to="/dashboard" /> : <Home />}
              </Route>
              <Route path="/dashboard">
                <Dashboard />
              </Route>
              <Route path="/session">
                <LiveSession />
              </Route>

              <Route path="/course">
                <Course />
              </Route>
              <Route path="/calendar">
                <Calendar />
              </Route>
              <Route path="/wishlist">
                <Wishlist />
              </Route>
              <Route path="/bookmark">
                <Bookmark />
              </Route>
              <Route path="/notifications">
                <Notifications />
              </Route>
              <Route path="/profile">
                <Profile />
              </Route>
              <Route path="/results">
                <Search />
              </Route>
              <Route path="/allSessions">
                <ViewAllSession />
              </Route>
              <Route path="/allCourses">
                <ViewAllCourses />
              </Route>
              <Route path="/certificates">
                <Certificate />
              </Route>
              <Route path="/invoices">
                <Invoices />
              </Route>
              <Route path="/helpandsupport">
                <Help />
              </Route>
              <Route path="/viewall">
                <ViewAll />
              </Route>
              <Route path="*">
                <Redirect to="/dashboard" />
              </Route>
            </Switch>
          </Layout>
        )}
      </Router>
    </ThemeProvider>
  );
};

export default App;
