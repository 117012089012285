import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment, TextField } from "@mui/material";

import React from "react";

const CustomInput = ({
  placeholder,
  type,
  name,
  onChange,
  inputValues,
  value,
  handleClickShowPassword,
  handleMouseDownPassword,
  id,
  error,
  ...props
}) => (
  <TextField
    error={error && true}
    id={id}
    placeholder={placeholder}
    InputProps={{
      startAdornment: props.children && (
        <InputAdornment position="start">{props.children}</InputAdornment>
      ),
      endAdornment: type === "password" && (
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
          >
            {inputValues.showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </InputAdornment>
      ),
      autoComplete: "new-password",
      form: {
        autoComplete: "off",
      },
      maxLength: name === "phoneNumber" && 12,
      style: { WebkitBoxShadow: "0 0 0 1000px white inset" },
    }}
    type={
      type === "password"
        ? inputValues.showPassword
          ? "text"
          : "password"
        : type
    }
    autoComplete="organization"
    name={name}
    value={value}
    onChange={onChange}
    helperText={error}
    variant="standard"
    focused
    fullWidth
  />
);

export default CustomInput;
