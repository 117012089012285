import React, { useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import API from "@aws-amplify/api";

import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Theme } from "../../config/aws-exports";
import { Box } from "@mui/system";
import AppBarDashboard from "../../components/AppBar/AppBar.Dashboard";

import { Backdrop, CircularProgress, Dialog } from "@mui/material";
import { authData } from "../../redux/auth/authSlice";
import config from "../../config/aws-exports";
import { useSelector } from "react-redux";

import { Container } from "../../components/Styles/Container";

import { useQuery } from "react-query";
import logo from "../../assets/USP logo R.png";
import usp from "../../assets/USP.png";
import signature from "../../assets/signature.png";
import moment from "moment";
import "./Certificate.css";
import CertificatePopUp from "../LiveSession/CertificatePopUp";
import emptysearch from "../../assets/Nothing here yet.gif";

export default function Certificate() {
  const userDetails = useSelector(authData);
  const [certificates, setCertificates] = useState({});
  const [certificateOpen, setCertificateOpen] = useState(false);

  const { data, isLoading, error } = useQuery(["certificates"], async () => {
    try {
      const bodyParam = {
        body: {
          oid: config.aws_org_id,
          tenant: userDetails.tenant,
          eid: userDetails.username,
          ur_id: userDetails?.uData?.ur_id,
          schema: config.schema,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/listUserCerts",
        bodyParam
      );
      return response;
    } catch (error) {
      console.error(error);
    }
  });

  const breadcrumbs = [
    <Typography key="3" color={Theme.color.text}>
      Certificates
    </Typography>,
  ];

  if (error)
    return (
      <Container m={true}>
        <AppBarDashboard />
        <Stack
          spacing={2}
          margin={{ xs: "1rem", sm: "3rem" }}
          paddingBottom="3rem"
        >
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
            sx={{ color: Theme.color.black }}
          >
            {breadcrumbs}
          </Breadcrumbs>
          <h3>My Certificates111</h3>

          <h3>{error.message}</h3>
        </Stack>
      </Container>
    );

  return (
    <>
      <Stack spacing={2} margin="3rem" paddingBottom="3rem">
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          sx={{ color: Theme.color.black }}
        >
          {breadcrumbs}
        </Breadcrumbs>
        <h3>My Certificates</h3>
        {data?.certs && data?.certs.length === 0 && (
          <div
            style={{
              height: "60vh",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <img src={emptysearch} alt="emptysearch" />
          </div>
        )}
        <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
          {data?.certs.map((item, idx) => (
            <div
              className="container-1"
              key={idx + "certificate"}
              onClick={() => {
                setCertificates(item);
                setCertificateOpen(true);
              }}
            >
              <div className="img-c">
                <img src={logo} alt="logo" height="30" />
              </div>
              <div className="org-container-1">
                <div className="img-c">
                  <img src={usp} alt="logo" height="30" />
                </div>
                <p>Certificate awarded to</p>
              </div>
              <h1 className="name-1">{userDetails.name}</h1>
              <p className="complete">for successfully completing</p>
              <div className="title-container-1">
                <h2 className="title-1">{item?.tn || item?.sn}</h2>
                <h2 className="title-1">

                  {item?.stype === 1 && "(Live Webinar)"}
                  {item?.stype === 2 && "(Classroom)"}
                  {item?.stype === 3 && "(Hybrid)"}
                  {item?.stype === undefined && "(On Demand Course)"}
                </h2>
              </div>

              <p className="date-1">
                Date : &nbsp;
                {item?.date_list &&
                  item?.date_list[0]?.combineStartTime &&
                  moment(item?.date_list[0]?.combineStartTime).format(
                    "DD MMM YYYY"
                  )}
                  {item?.stype === undefined && item?.comp_date &&
                  moment(item?.comp_date).format(
                    "DD MMM YYYY"
                  )}
                {/* {item?.date_list?.[0]?.combineStartTime &&
                  moment(item?.date_list?.[0]?.combineStartTime).format(
                    "DD MMM YYYY"
                  )}
                {item?.date_list?.length > 1 &&
                  item?.date_list?.[item?.date_list?.length - 1]
                    ?.combineEndTime &&
                  " - " +
                    moment(
                      item?.date_list?.[item?.date_list?.length - 1]
                        ?.combineEndTime || item?.comp_date
                    ).format("DD MMM YYYY")} */}
              </p>
              <p className="complete">
                {" "}
                {item?.location_value == null ||
                  item?.location_value === "null" ||
                  item?.location_value === undefined
                  ? ""
                  : item?.location_value}
              </p>
              <div className="img-c">
                <img src={signature} alt="signature" height="50" />
              </div>
            </div>
          ))}
        </Box>
      </Stack>
      <Dialog
        open={certificateOpen}
        aria-labelledby="responsive-dialog-title-certificateOpen"
        scroll="paper"
        maxWidth="xl"
      >
        <CertificatePopUp
          open={certificateOpen}
          setOpen={setCertificateOpen}
          sData={certificates}
          name={
            userDetails?.uData?.first_name + " " + userDetails?.uData?.last_name
          }
          type={'profile'}
        />
      </Dialog>

      <Backdrop open={isLoading} sx={{ zIndex: "theme.drawer" + 1 }}>
        <CircularProgress />
      </Backdrop>
    </>
  );
}
