import { Box } from "@mui/system";
import React, { useContext, useState, useEffect } from "react";

import { API, Auth } from "aws-amplify";

import { awsSignIn } from "../../redux/auth/authSlice";

import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { CircularProgress, Stack, Typography } from "@mui/material";
import { CustomButton } from "../CustomButton/CustomButton";
import {
  inputBoxStyle,
  inputIconStyle,
  displayCenter,
  SignInAndSignUpValues,
} from "./SignInAndSignUp";

import { useHistory } from "react-router-dom";
import CustomInput from "../CustomInput/CustomInput";
// import "react-phone-number-input/style.css";

import { useForm } from "../../Hooks/useForm";
import ResetPassword from "./ResetPassword";
import ForgotPassword from "./ForgotPassword";
// import ReactCodeInput from "react-verification-code-input";
import { Theme } from "../../config/aws-exports";
import { useDispatch, useSelector } from "react-redux";
import validate from "./SignInSignUpValidationRules/SignInValidationRules";
import cognitoValidation from "./SignInSignUpValidationRules/SignInCognitoValidationRules";
import { HeadingPrimary } from "../Styles/Heading";
import Swal from "sweetalert2";
import config from "../../config/aws-exports";

import validateResetPasswordEmail from "./SignInSignUpValidationRules/ResetPasswordValidationEmail";
// import { SettingsBrightnessOutlined } from "@mui/icons-material";
import { useQueryParams } from "../../Hooks/useQueryParams";
import validateResetPasswordVerification, {
  validateResetPassword,
} from "./SignInSignUpValidationRules/ResetPasswordValidation";
const SignIn = ({ sid }) => {
  const history = useHistory();
  const query = useQueryParams();
  const sessionData = useSelector((state) => state.sessionData.value);
  const dispatch = useDispatch();
  const {
    values,
    handleChange,
    setValues,
    handleClickShowPassword,
    handleMouseDownPassword,
  } = useForm({ showPassword: false });

  const { setSignUp, setSignIn } = useContext(SignInAndSignUpValues);

  const [forgotPassword, setForgotPassword] = useState(false);
  const [resetPasswordOpenFirst, setResetPasswordOpenFirst] = useState(false);
  const [signInForm, setSignInForm] = useState(true);
  const [emailVerification, setEmailVerification] = useState(false);

  // const [verificationCode, setVerificationCode] = useState("");

  const [errors, setErrors] = useState({});

  const [btnLoading, setBtnLoading] = useState(false);
  const [emailVerifyLoader, setEmailVerifyLoader] = useState(false);
  const [resetPasswordLoader, setResetPasswordLoader] = useState(false);
  const analyticsAPI = async (data) => {
    //console.log("lda ", datagot.attributes.sub);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        id: data.id,
        eventtype: "AuthenticatedViaCognito",
        email: data.sub,
        emailid: data.email,
        name: data.name,
        tenant: data.tenant,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    console.log(JSON.stringify(bodyParam.body, null, 2));

    try {
      const res = await API.post(
        config.aws_cloud_logic_custom_name,
        "/analyticsWebApp",
        bodyParam
        //`${Constants.GET_MY_PROGRAMS}`, bodyParam,
      );
      console.log(res);
    } catch (error) {
      console.log("getCategoryError", error);
    }
  };
  useEffect(() => {
    console.log(sid, "sid==");
  }, []);
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (Object.keys(validate(values)).length !== 0) {
      setErrors(validate(values));
      return;
    } else {
      setBtnLoading(true);
      try {
        const user = await Auth.signIn(values.username, values.password);

        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          setSignInForm(false);
          setResetPasswordOpenFirst(true);
          setSignUp(false);
          setValues({ ...values, showPassword: false });
          setForgotPassword(false);
          setBtnLoading(false);
        }
        const info = await Auth.currentUserInfo();
        let userData = info.attributes;
        userData.username = info.username;
        userData.id = info.id;
        userData.tenant = userData.locale;

        //analyticsAPI(userData);

        getUserDetails(userData);
      } catch (error) {
        console.error(error.code);
        setBtnLoading(false);
        setErrors(cognitoValidation(error));
      }
    }
  };
  const getUserDetails = async (userdata) => {
    const bodyParam = {
      body: {
        emailid: userdata.email,
        schema: config.schema,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/getUserDetails",
        bodyParam
      );

      if (response && response.body.length > 0) {
        console.log(response);
        userdata.uData = response.body[0];
        console.log("userData==", JSON.stringify(userdata));
        dispatch(awsSignIn(userdata));

        analyticsWebApp(userdata);
      }
    } catch (err) {
      console.error(err);
    }
  };
  async function analyticsWebApp(userDetails) {
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        eventtype: "AuthenticatedViaCognito",
        id: userDetails.id,
        iid: config.aws_cognito_identity_pool_id,
        email: userDetails.username,
        name: userDetails.name,
        emailid: userDetails.email,
        tenant: userDetails.locale,
        ur_id: userDetails.uData?.ur_id,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        // Authorization: jwttoken,
      },
    };
    // bodyParam.body.emailid = userDetails.emailid;
    console.log("analytic==" + JSON.stringify(bodyParam.body));

    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/analyticsWebApp",
        bodyParam
      );
      const courseId = query.get("id");

      if (
        (query.get("sid") !== undefined && query.get("sid") !== null) ||
        sid !== undefined
      ) {
        let sessionid =
          query.get("sid") !== undefined && query.get("sid") !== null
            ? query.get("sid")
            : sid;
        history.push(`/session?sid=${sessionid}`);
      } else if (courseId) {
        history.push(`/course?id=${courseId}`);
      } else {
        history.push("/dashboard");
      }
      setBtnLoading(false);
    } catch (err) {
      // history.push("/");
    }

    // window.location.href = homeurl;
  }
  const resetPasswordFC = async (event) => {
    event.preventDefault();
    console.log(validateResetPassword(values));
    if (Object.keys(validateResetPassword(values)).length !== 0) {
      setErrors(validateResetPassword(values));
      return;
    } else {
      try {
        setResetPasswordLoader(true);
        await Auth.signIn(values.username, values.password).then((users) => {
          if (users.challengeName === "NEW_PASSWORD_REQUIRED") {
            Auth.completeNewPassword(users, values.confirmPassword);
          }
        });
        setResetPasswordLoader(false);
        Swal.fire({
          title: "Success",
          text: "Password Changed Successfully",
          icon: "success",
          confirmButtonColor: Theme.color.primary,
          confirmButtonText: "Ok",
        }).then((willDelete) => {
          if (willDelete) {
            setSignInForm(true);
            setResetPasswordOpenFirst(false);
          }
        });
      } catch (error) {
        console.error(error);
        setErrors(cognitoValidation(error));
      }
    }
  };

  const passwordResetFC = async (event) => {
    event.preventDefault();

    if (Object.keys(validateResetPasswordEmail(values)).length !== 0) {
      setErrors(validateResetPasswordEmail(values));
      return;
    } else {
      if (Object.keys(validateResetPasswordVerification(values)).length !== 0) {
        setErrors(validateResetPasswordVerification(values));
        return;
      } else {
        setResetPasswordLoader(true);
        try {
          await Auth.forgotPasswordSubmit(
            values.verificationEmail,
            values.verificationCode,
            values.confirmPassword
          );
          setResetPasswordLoader(false);
          Swal.fire({
            title: "Success",
            text: "Password Changed Successfully",
            icon: "success",
            confirmButtonColor: Theme.color.primary,
            confirmButtonText: "Ok",
          }).then((willDelete) => {
            if (willDelete.isConfirmed) {
              setSignIn(true);
              setSignInForm(true);
              setForgotPassword(false);
              setSignUp(false);
            }
          });

          // alert("Changed password Successfully");
        } catch (error) {
          setResetPasswordLoader(false);
          setErrors(cognitoValidation(error));
          console.error(error);
        }
      }
    }
  };

  return (
    <React.Fragment>
      {signInForm && (
        <React.Fragment>
          <Box sx={{ textAlign: "center" }}>
            <HeadingPrimary>Login to your account</HeadingPrimary>
          </Box>
          <form
            onSubmit={handleSubmit}
            noValidate
            autoComplete="off"
            style={displayCenter}
          >
            <Box component="div" sx={inputBoxStyle}>
              <CustomInput
                id="input-username"
                placeholder="Username"
                type="email"
                value={values.username || ""}
                error={errors.email}
                onChange={handleChange("username")}
              >
                <PersonOutlineOutlinedIcon sx={inputIconStyle} />
              </CustomInput>
            </Box>

            <Box component="div" sx={inputBoxStyle}>
              <CustomInput
                id="input-Password"
                placeholder="Password"
                type="password"
                inputValues={values}
                value={values.password || ""}
                handleClickShowPassword={handleClickShowPassword}
                handleMouseDownPassword={handleMouseDownPassword}
                onChange={handleChange("password")}
                error={errors.password}
              >
                <LockOutlinedIcon sx={inputIconStyle} />
              </CustomInput>
              <Stack direction="row" justifyContent="flex-end">
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  sx={{
                    fontSize: "1rem",
                    textAlign: "right",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setForgotPassword(true);
                    setSignInForm(false);
                  }}
                >
                  Forgot password?
                </Typography>
              </Stack>
            </Box>

            <Box component="div" sx={{ textAlign: "center", mb: 3 }}>
              {btnLoading ? (
                <CustomButton disabled loading="true">
                  <CircularProgress color="inherit" size={20} />
                </CustomButton>
              ) : (
                <CustomButton type="submit" primary>
                  Login
                </CustomButton>
              )}
            </Box>
          </form>
          <Typography
            onClick={() => {
              setSignIn(false);
              setSignUp(true);
              setValues({});
            }}
            variant="caption"
            display="block"
            gutterBottom
            sx={{ fontSize: "1rem", textAlign: "center", cursor: "pointer" }}
          >
            Are you a new user?
            <span
              style={{
                textDecoration: "underline",
                color: Theme.color.secondary,
                fontWeight: "bold",
              }}
            >
              Sign up here
            </span>
          </Typography>
        </React.Fragment>
      )}

      {forgotPassword && !resetPasswordOpenFirst && (
        <ForgotPassword
          emailVerifyLoader={emailVerifyLoader}
          setEmailVerifyLoader={setEmailVerifyLoader}
          setEmailVerification={setEmailVerification}
          passwordResetFC={passwordResetFC}
          setForgotPassword={setForgotPassword}
          setResetPasswordOpenFirst={setResetPasswordOpenFirst}
          values={values}
          handleChange={handleChange}
          resetPasswordLoader={resetPasswordLoader}
          emailVerification={emailVerification}
          handleClickShowPassword={handleClickShowPassword}
          handleMouseDownPassword={handleMouseDownPassword}
          setErrors={setErrors}
          errors={errors}
        />
      )}

      {resetPasswordOpenFirst && (
        <ResetPassword
          resetPasswordFC={resetPasswordFC}
          resetPasswordLoader={resetPasswordLoader}
          emailVerification={emailVerification}
          handleChange={handleChange}
          values={values}
          setValues={setValues}
          handleClickShowPassword={handleClickShowPassword}
          handleMouseDownPassword={handleMouseDownPassword}
          setErrors={setErrors}
          errors={errors}
        />
      )}
    </React.Fragment>
  );
};

export default SignIn;
