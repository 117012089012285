import styled from "styled-components";

export const Container = styled.div`
  margin-top: ${({ m }) => (m ? "95px" : "0px")};

  a {
    text-decoration: underline;
    color: ${({ theme }) => theme.color.secondary};
  }
  #liveSession {
    tr > :first-child {
      text-align: right;
    }
    tr > :last-child {
      text-align: left;
    }
  }

  ul {
    margin: 0;
  }
`;
