//  function RatingPopUp({ courseDetails }) {
//   // const classes = useStyles();

//   const history = useHistory();
//   let userDetails = useSelector(authData);
//   const [value, setValue] = React.useState(1);
//   const [hover, setHover] = React.useState(-1);
//   const [open, setOpen] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const handleOpen = () => {
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };
//   async function handleRateSubmit() {
//     setLoading(true);
//     const bodyParam = {
//       body: {
//         emailid: userDetails.email,
//         rating_date: 1,
//         id: userDetails.id,
//         iid: config.aws_cognito_identity_pool_id,
//         tid: courseDetails.tid,
//         email: userDetails.username,
//         rating: value,
//         oid: config.aws_org_id,
//         uname: userDetails.name,
//         tenant: userDetails.locale,
//         ur_id: userDetails.uData.ur_id,
//       },
//       headers: {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//       },
//     };

//     try {
//       console.log("rateresponse===" + JSON.stringify(bodyParam.body));
//       const response = await API.post(
//         config.aws_cloud_logic_custom_name,
//         "/updateTopicReport",
//         bodyParam
//       );
//       console.log(response);
//       //setLoading(false);
//       history.push(`/course?id=${courseDetails.tid}`);

//       window.location.reload();
//     } catch (error) {
//       console.error(error);
//     }
//   }
//   const style = {
//     position: "absolute",
//     top: "50%",
//     left: "50%",
//     transform: "translate(-50%, -50%)",
//     width: 400,
//     bgcolor: "background.paper",
//     border: "2px solid #000",
//     boxShadow: 24,
//     p: 4,
//   };
//   return (
//     <div>
//       {courseDetails.userdataset !== undefined &&
//       courseDetails.userdataset.userdata == true &&
//       courseDetails.userdataset.userprogress !== undefined &&
//       courseDetails.userdataset.userprogress.rating !== null ? (
//         <></>
//       ) : (
//         <>
//           {courseDetails.userdataset.userprogress.comp_date !== null && (
//             <Button
//               variant="outlined"
//               color="primary"
//               // style={{ fontSize: "1.5rem" }}
//               onClick={handleOpen}
//             >
//               Rate Course
//             </Button>
//           )}
//         </>
//       )}

//       <Modal
//         aria-labelledby="transition-modal-title"
//         aria-describedby="transition-modal-description"
//         style={{
//           display: "flex",
//           alignItems: "center",
//           justifyContent: "center",
//           backgroundColor: "rgba(0, 0, 0, 0.73)",
//           paddingTop: "1%",
//         }}
//         open={open}
//         closeAfterTransition
//         BackdropComponent={Backdrop}
//         BackdropProps={{
//           timeout: 500,
//         }}
//       >
//         <Fade in={open}>
//           <div style={style}>
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "space-between",
//                 alignItems: "center",
//               }}
//             >
//               <Typography variant="h6" id="transition-modal-title"></Typography>
//               <button
//                 onClick={handleClose}
//                 style={{
//                   border: "none",
//                   background: "transparent",
//                   cursor: "pointer",
//                 }}
//               >
//                 <CloseIcon fontSize="large" />
//               </button>
//             </div>
//             <div
//               // className={classes.ratingroot}
//               style={{
//                 width: 300,
//                 display: "flex",
//                 justifyContent: "center",
//                 alignItems: "center",
//                 flexDirection: "column",
//                 gap: "1.5rem",
//               }}
//             >
//               <Typography
//                 variant="h6"
//                 style={{
//                   width: "330px",
//                   fontSize: "2.5rem",
//                   textAlign: "center",
//                   marginLeft: "6rem",
//                 }}
//                 id="transition-modal-title"
//               >
//                 Course completed!
//               </Typography>
//               <Rating
//                 name="hover-feedback"
//                 icon={<StarRoundedIcon style={{ fontSize: "5rem" }} />}
//                 value={value}
//                 onChange={(event, newValue) => {
//                   setValue(newValue);
//                 }}
//                 onChangeActive={(event, newHover) => {
//                   setHover(newHover);
//                 }}
//               />
//               {value == null && (
//                 <Box
//                   ml={2}
//                   style={{
//                     color: "red",
//                     fontSize: "12px",
//                     width: "100%",
//                     marginLeft: "100px",
//                   }}
//                 >
//                   You must select a rating before you can click Submit
//                 </Box>
//               )}
//               {loading ? (
//                 <StyledButton style={{ marginLeft: "50px" }}>
//                   Loading..
//                 </StyledButton>
//               ) : (
//                 <StyledButton
//                   style={{ marginLeft: "50px" }}
//                   onClick={() => {
//                     if (value !== null) {
//                       handleRateSubmit();
//                     }
//                   }}
//                 >
//                   Submit
//                 </StyledButton>
//               )}
//             </div>
//           </div>
//         </Fade>
//       </Modal>
//     </div>
//   );
// }

// export default RatingPopUp;

import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Close, StarRounded } from "@mui/icons-material";
import { IconButton, Rating } from "@mui/material";
import { HeadingSecondary } from "../../components/Styles/Heading";
import { useSelector } from "react-redux";
import { authData } from "../../redux/auth/authSlice";

import config, { Theme } from "../../config/aws-exports";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { useHistory } from "react-router-dom";
import { API } from "aws-amplify";
import { Box } from "@mui/system";
import Swal from "sweetalert2";

function RatingPopUp({ open, setRateOpen, courseDetails, close ,getTopicDetails}) {
  const [value, setValue] = React.useState(0);

  const [loading, setLoading] = useState(false);
  const userDetails = useSelector(authData);

  const history = useHistory();

  async function handleRateSubmit() {
    setLoading(true);
    const bodyParam = {
      body: {
        emailid: userDetails.email,
        rating_date: 1,
        id: userDetails.id,
        iid: config.aws_cognito_identity_pool_id,
        tid: courseDetails.tid,
        email: userDetails.username,
        rating: value,
        oid: config.aws_org_id,
        uname: userDetails.name,
        tenant: userDetails.locale,
        ur_id: userDetails.uData.ur_id,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      console.log("rateresponse===" + JSON.stringify(bodyParam.body));
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/updateTopicReport",
        bodyParam
      );
      setLoading(false);
      Swal.fire({title:"Success",text:'Successfully Submitted Your Rating',icon:'success'}).then((willDelete)=>{
        if(willDelete){
          setRateOpen(false);
        }
      });
      setRateOpen(false);
      getTopicDetails()
      console.log(response);
      //setLoading(false);
      history.push(`/course?id=${courseDetails.tid}`);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  }

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <HeadingSecondary>Please rate this course</HeadingSecondary>
          <IconButton onClick={close}>
            <Close sx={{ fill: "#777" }} />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ padding: "2rem 5rem" }}>
          <Rating
            name="hover-feedback"
            value={value}
            precision={0.5}
            icon={<StarRounded style={{ fontSize: "4rem" }} />}
            // getLabelText={getLabelText}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
            emptyIcon={
              <StarRounded
                style={{ opacity: 0.55, fontSize: "4rem" }}
                fontSize="inherit"
              />
            }
          />
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
          <LoadingButton
            // sx={{
            //   backgroundColor: Theme.color.secondary,
            //   color: Theme.color.white,
            // }}
            variant="contained"
            sx={{
              backgroundColor: Theme.color.secondary,
              color: Theme.color.white,
            }}
            loading={loading}
            onClick={handleRateSubmit}
            autoFocus
          >
            Submit
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default RatingPopUp;
