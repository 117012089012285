export default function validateSignUp(values) {
  let errors = {};
  if (!values.name) {
    errors.name = "First name is required";
  }
  if (!values.lname) {
    errors.lname = "Last name is required";
  }
  if (!values.phoneNumber) {
    errors.phoneNumber = "Phone number is required";
  } else if (values.phoneNumber.length < 10) {
    errors.phoneNumber = "Enter valid phone number";
  } else if (values.phoneNumber.length > 18) {
    errors.phoneNumber = "Enter valid phone number";
  }

  if (!values.email) {
    errors.email = "Email address is required";
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = "Email address is invalid";
  }

  if (!values.signUpPassword) {
    errors.password = "Password is required";
  } else if (values.signUpPassword.length < 8) {
    errors.password = "Password must be 8 or more characters";
  } else if (!/\d/.test(values.signUpPassword)) {
    errors.password = "Password must contain atleast 1 number";
  } else if (!/[!@#$%&?]/g.test(values.signUpPassword)) {
    errors.password = "Password must contain atleast 1 special character";
  }
  // else if (!/[A-Z]/g.test(values.password)) {
  //   errors.password = "Password must contain atleast 1 capitol letter";
  // }

  return errors;
}
