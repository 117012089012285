/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import PropTypes from "prop-types";
import TimerIcon from "@mui/icons-material/Timer";
import { Stack } from "@mui/material";

function TimerviewProgressWithLabel(props) {
  return (
    <>
      {props.value <= 0 ? (
        <></>
      ) : (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{ position: "absolute", top: "37px", right: "120px",left:'10px' }}
        >
          <TimerIcon />
          <span>
            {`${Math.floor(props.value / 60)}m : ${Math.floor(
              props.value % 60
            )}s`}
          </span>
        </Stack>
      )}
    </>
  );
}

TimerviewProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

function TimerviewStatic({
  courseDetails,
  curNugget,
  curObjIndex,
  progress,
  setProgress,
  timerPalyPause,
  setTimerPalyPause,
  setMarkComplete,
  handleQuizSubmit,
  quizQuestion,
  setLoading,
  questionsRef,
}) {
  const counterRef = React.useRef(progress);

  React.useEffect(() => {
    const timer = setInterval(() => {
      if (timerPalyPause === "play") {
        // setProgress(prevProgress => prevProgress - 1);
        setProgress(counterRef.current - 1);
        counterRef.current = counterRef.current - 1;
      }
      //console.log("progress=="+counterRef.current)
      if (counterRef.current <= 0) {
        console.log("curNugget==" + curNugget);
        console.log("curObjIndex==" + curObjIndex);
        console.log(curNugget.objects[curObjIndex].otype);
        if (curNugget.objects[curObjIndex].otype === "quiz") {
          handleQuizSubmit();
        } else {
          setMarkComplete(true);
        }
        setTimerPalyPause("pause");
        clearInterval(timer);
      }
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return <TimerviewProgressWithLabel value={progress} />;
}

export default TimerviewStatic;
