import React from "react";
import AppBarDashboard from "../../../components/AppBar/AppBar.Dashboard";
import { Container } from "../../../components/Styles/Container";
import { Wrapper } from "../../Course/ViewAllCourses/ViewAllCourses.styles";

const ViewAllSession = () => {
  return (
    <Wrapper>
      <Container>
        <AppBarDashboard />
      </Container>
    </Wrapper>
  );
};

export default ViewAllSession;
