import { Breadcrumbs, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import { Theme } from "../../config/aws-exports";
import { HeadingPrimary } from "../../components/Styles/Heading";
import { Wrapper } from "./Bookmark.styles";

import { useDispatch, useSelector } from "react-redux";
import { authData, awsSignIn } from "../../redux/auth/authSlice";
import { Link, useHistory } from "react-router-dom";
import { API } from "aws-amplify";

import config from "../../config/aws-exports";
import CardSecondary from "../../components/Card/Card.Secondary";
import moment from "moment";
import session from "redux-persist/lib/storage/session";
import { useQuery } from "react-query";
import emptysearch from "../../assets/Nothing here yet.gif";

const Bookmark = () => {
  let userDetails = useSelector(authData);
  const dispatch = useDispatch();
  const { data, error, isLoading, refetch } = useQuery(
    "/bookMarkList",
    async () => {
      const bodyParam = {
        body: {
          ur_id: userDetails?.uData?.ur_id,
          schema: config.schema,
          tenant: userDetails.tenant,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };
      console.log(bodyParam);
      try {
        const response = await API.post(
          config.aws_cloud_logic_custom_name,
          "/getMyInterests",
          bodyParam
        );
        const { items } = response;
        console.log("list", items);
        const bookmarkList = items.filter((item) => item.type === 1);

        return bookmarkList;
      } catch (err) {
        console.error(err);
      }
    }
  );
  useEffect(() => {
    if (userDetails === 0) {
      window.location.href = config.aws_cloudfront_lurl;
    }
    // let filterstoredata = { ...userDetails };
    // delete filterstoredata.filter;
    // dispatch(awsSignIn(filterstoredata));
  }, [userDetails]);

  const history = useHistory();

  /*  let src1 = `${config.aws_cloudfront_url}marketron-resources/images/program-images/${pid}.png`; */

  async function deleteBookmark(tid, type) {
    const bodyParam = {
      body: {
        ur_id: userDetails.uData.ur_id,
        schema: config.schema,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    if (type === 1) {
      bodyParam.body.tid = tid;
    } else {
      bodyParam.body.objid = tid;
    }
    bodyParam.body.bookmark = false;

    try {
      await API.post(
        config.aws_cloud_logic_custom_name,
        type === 1 ? "/updateTopicReport" : "/updateContentReport",
        bodyParam
      );
      refetch();
    } catch (err) {
      console.error(err);
    }
  }

  const breadcrumbs = [
    <Typography key="3" color={Theme.color.text}>
      Bookmark
    </Typography>,
  ];
  return (
    <Wrapper>
      <Stack spacing={2} margin={{ xs: "1rem", sm: "3rem" }}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          sx={{ color: Theme.color.black }}
        >
          {breadcrumbs}
        </Breadcrumbs>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <HeadingPrimary>Bookmark</HeadingPrimary>

          {/* <pre>{JSON.stringify(list, null, 2)}</pre> */}
        </Stack>
        {data?.length === 0 && (
          <div
            style={{
              height: "60vh",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <img src={emptysearch} alt="emptysearch" />
            {/* <h3>Nothing here yet!</h3> */}
          </div>
        )}
        <Grid container spacing={2}>
          {!isLoading &&
            data?.map((item, idx) => (
              <Grid xs={12} sm={6} md={4} lg={4}>
                <CardSecondary
                  key={idx}
                  img={
                    item?.tid &&
                    `https://${
                      config.aws_cloudfront_url
                    }/${config.aws_org_id.toLowerCase()}-resources/images/${
                      item.type === 1 ? "topic" : "session"
                    }-images/${item?.tid}.png`
                  }
                  title={item.tname[0].title}
                  bookmark={true}
                  date={item?.bookmark_date}
                  // handleLocation={() => {
                  //   history.push(`/course?id=${item.tid}`);
                  // }}
                  handleLocation={() => {
                    history.push({
                      pathname: `/course`,
                      search: `?id=${item.tid}`,
                      hash: "bookmark",
                    });
                  }}
                  handleDelete={() => deleteBookmark(item?.tid, item?.type)}
                />
              </Grid>
            ))}
        </Grid>
      </Stack>
    </Wrapper>
  );
};

export default Bookmark;
