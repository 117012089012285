import React, { useRef, useState, useEffect } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Theme } from "../../config/aws-exports";
import { Box } from "@mui/system";
import AppBarDashboard from "../../components/AppBar/AppBar.Dashboard";
import {
  HeadingPrimary,
  HeadingSecondary,
} from "../../components/Styles/Heading";
import { Backdrop, CircularProgress, Paper } from "@mui/material";

import { CustomButton } from "../../components/CustomButton/CustomButton";
import { useHistory } from "react-router-dom";

import { useTheme } from "@mui/material/styles";

import { useDispatch, useSelector } from "react-redux";

import AppBarLandingPage from "../../components/AppBar/AppBar.landingPage";
import moment from "moment";

import { API } from "aws-amplify";
import config from "../../config/aws-exports";
import { authData } from "../../redux/auth/authSlice";

import SpeakerDialog from "../LiveSession/SpeakerDialog";
import { ArrowLeft } from "@mui/icons-material";
import SignInAndSignUp from "../../components/SignInAndSignUp/SignInAndSignUp";
const INITIAL_STATE = {
  open: false,
  buttonState: {
    join: false,
    expInterest: false,
    registered: false,
    sessionCompleted: false,
    clickState: {
      registerButton: false,
      feedbackButton: false,
    },
  },
};
const PreLoginLiveSession = () => {
  // const history = useHistory();
  // const dispatch = useDispatch();
  // const userDetails = useSelector(authData);

  const [loginOpen, setLoginOpen] = useState(false);
  const onlySignUp = useRef(false);
  const sessionData = useSelector((state) => state.sessionData.value);
  console.log({sessionData});

  const [openSpeaker, setSpeaker] = useState(false);

  const [speakerDetails, setSpeakerDetails] = useState({});
  const [regButton,setRegButton] = useState(true);

  useEffect(() => {
    console.log({sessionData});
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    // const curTime = sessionData?.currentTime;
    const startTime = sessionData?.date_list?.[0].combineStartTime;
    if (moment().isAfter(moment(startTime))) {
      setRegButton(false);
    }
  }, [sessionData]);

  const handleClickSpeakerOpen = async (details) => {
    setSpeaker(true);
    setSpeakerDetails(details);
  };

  const handleLoginClickOpen = (prop) => () => {
    if (prop === "login") {
      setLoginOpen(true);
      onlySignUp.current = false;
    } else {
      setLoginOpen(true);
      onlySignUp.current = true;
    }
  };

  return (
    <>
      <AppBarLandingPage handleLoginClick={handleLoginClickOpen} />

      <Stack spacing={2} margin="3rem">
        <Link to="/" style={{ display: "flex" }}>
          <div>
            <ArrowLeft />
          </div>
          <div>Back</div>
        </Link>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <HeadingPrimary>{sessionData?.sn}</HeadingPrimary>
        </Stack>
        <Stack
          direction={{ xs: "column-reverse", md: "row" }}
          spacing={{ xs: 1, md: 4 }}
        >
          <Paper
            elevation={5}
            sx={{
              p: 3,
              borderRadius: "4px",
              textAlign: "justify",
              width: "100%",
            }}
          >
            <Stack direction="row">
              <Stack
                direction="column"
                alignItems="center"
                justifyContent="center"
                spacing={2}
                sx={{ flexGrow: 1 }}
              >
                <HeadingSecondary>
                  {sessionData?.date_list && (
                    <table className="tableStyle">
                      <tbody>
                        <tr>
                          <td style={{ verticalAlign: "top" }}>
                            <HeadingSecondary>
                              Session Date & Time :
                            </HeadingSecondary>
                          </td>

                          {sessionData?.date_list?.map((item) => {
                            return (
                              <tr>
                                <td>
                                  {moment(item.combineStartTime).format(
                                    "DD/MM/YYYY"
                                  )}
                                  <span style={{ marginLeft: "5px" }}></span>;
                                  <span style={{ marginLeft: "5px" }}></span>
                                  {moment(item.combineStartTime).format("LT")}
                                  <span style={{ marginLeft: "5px" }}></span>-
                                  <span style={{ marginLeft: "5px" }}></span>
                                  {moment(item.combineEndTime).format("LT")}
                                </td>
                              </tr>
                            );
                          })}
                        </tr>
                      </tbody>
                    </table>
                  )}
                </HeadingSecondary>
              </Stack>
              <Stack
                direction="column"
                alignItems="center"
                justifyContent="center"
                spacing={2}
                sx={{ flexGrow: 1 }}
              >
                <table
                  id="liveSession"
                  style={{
                    textAlign: "left",
                    borderCollapse: "separate",
                    borderSpacing: "10px 10px",
                  }}
                >
                  <tr>
                    <td>Speaker &#58;</td>
                    <td>
                      <Link
                        to="#"
                        onClick={async () => {
                          handleClickSpeakerOpen(sessionData?.speakers[0]);
                        }}
                      >
                        {sessionData?.speakers?.length !== 0 &&
                          sessionData?.speakers[0].name}
                      </Link>
                    </td>
                  </tr>
                  {sessionData?.speakers?.map((speaker, idx) => {
                    let rightSpeaker = [];
                    if (idx !== 0) {
                      rightSpeaker.push(speaker);
                    }
                    return (
                      <React.Fragment key={idx}>
                        {rightSpeaker.map((sp, id) => (
                          <tr key={sp.id + "jsndf"}>
                            <td></td>
                            <td>
                              <Link
                                to="#"
                                onClick={() => {
                                  handleClickSpeakerOpen(sp);
                                }}
                              >
                                {sp.name}
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </React.Fragment>
                    );
                  })}

                  {(sessionData?.priceinr > 0 || sessionData?.priceusd) > 0 &&
                    (sessionData?.paid === true ||
                      sessionData?.paid === "true") && (
                      <tr>
                        <td>Fees &#58;</td>
                        <td>
                          <p>
                            {" "}
                            INR {sessionData?.priceinr} + GST / $
                            {sessionData?.priceusd}{" "}
                          </p>
                        </td>
                      </tr>
                    )}

                  {sessionData?.earlypriceinr &&
                    sessionData?.earlypriceusd &&
                    (sessionData?.earlypriceinr > 0 ||
                      sessionData?.earlypriceusd > 0) &&
                    sessionData?.earlydate &&
                    (sessionData?.paid === true ||
                      sessionData?.paid === "true") && (
                      <tr>
                        <td>Early Bird Offer &#58;</td>
                        <td>
                          <p>
                            INR {sessionData?.earlypriceinr ?? ""} + GST
                            {sessionData?.earlypriceusd > 0 && (
                              <>
                                /{" "}
                                <Box component="span" sx={{ mx: 0.3 }}>
                                  {" "}
                                  USD{" "}
                                </Box>
                                {sessionData?.earlypriceusd ?? ""} till{" "}
                                {moment(
                                  parseInt(sessionData?.earlydate)
                                ).format("DD MMM YYYY")}
                              </>
                            )}
                          </p>
                        </td>
                      </tr>
                    )}

                  {sessionData?.perdis !== null &&
                    sessionData?.perdis > 0 &&
                    (sessionData?.paid === true ||
                      sessionData?.paid === "true") && (
                      <tr>
                        <td>Percentage Discount &#58;</td>
                        <td>
                          <p>{sessionData?.perdis} %</p>
                        </td>
                      </tr>
                    )}
                  {sessionData?.priceafterinr !== null &&
                    sessionData?.priceafterusd !== null &&
                    (sessionData?.priceafterinr > 0 ||
                      sessionData?.priceafterusd > 0) &&
                    sessionData?.perdis > 0 && (
                      <tr>
                        <td>Price after Discount &#58;</td>
                        <td>
                          <p>
                            INR {sessionData?.priceafterinr} + GST /{" "}
                            <Box component="span" sx={{ mx: 0.3 }}>
                              {" "}
                              USD{" "}
                            </Box>
                            {sessionData?.priceafterusd}{" "}
                          </p>
                        </td>
                      </tr>
                    )}
                  {sessionData?.stype !== null &&
                    sessionData?.note !== "null" &&
                    sessionData?.note !== "" && (
                      <tr>
                        <td>Note &#58;</td>
                        <td>
                          <p>{sessionData?.note}</p>
                        </td>
                      </tr>
                    )}
                </table>
              </Stack>
            </Stack>
            {
              regButton && (
                <Stack direction="row" justifyContent="center" alignItems="center">
              <CustomButton primary onClick={handleLoginClickOpen("login")}>
                Register
              </CustomButton>
            </Stack>
              )
            }
          </Paper>

          <img
            src={`https://${
              config.aws_cloudfront_url
            }/${config.aws_org_id.toLowerCase()}-resources/images/session-images/${
              sessionData?.sid
            }.png`}
            alt=""
            height="200"
            width="auto"
            style={{ borderRadius: "4px" }}
          />
        </Stack>
        <>
          <Paper elevation={5} sx={{ p: 3, mt: 2 }}>
            {sessionData?.lobj !== "null" &&
              sessionData?.sdesc !== "" &&
              sessionData?.sdesc !== null && (
                <>
                  <HeadingSecondary>Overview</HeadingSecondary>
                  <p>{sessionData?.sdesc}</p>
                </>
              )}
            {sessionData?.lobj !== "null" &&
              sessionData?.lobj !== "" &&
              sessionData?.lobj !== null && (
                <>
                  <HeadingSecondary style={{ marginTop: "10px" }}>
                    Learning objectives :{" "}
                  </HeadingSecondary>
                  <ul>
                    {sessionData?.lobj
                      ?.split("\n")
                      .map(
                        (obj, idx) =>
                          obj.replace(/\s/g, "") !== "" && (
                            <li key={idx + "sddgd"}>{obj}</li>
                          )
                      )}
                  </ul>
                </>
              )}
            {sessionData?.taudi !== "null" &&
              sessionData?.taudi !== "" &&
              sessionData?.taudi !== null && (
                <>
                  <HeadingSecondary style={{ marginTop: "10px" }}>
                    Target audience:{" "}
                  </HeadingSecondary>
                  <ul>
                    {sessionData?.taudi
                      ?.split("\n")
                      .map(
                        (obj, id) =>
                          obj.replace(/\s/g, "") !== "" && (
                            <li key={id + "sdf"}>{obj}</li>
                          )
                      )}
                  </ul>
                </>
              )}
          </Paper>
        </>
      </Stack>
      {openSpeaker && (
        <SpeakerDialog
          open={openSpeaker}
          setOpen={setSpeaker}
          speakerDetails={speakerDetails}
        />
      )}
      {loginOpen && (
        <SignInAndSignUp
          loginOpen={loginOpen}
          setLoginOpen={setLoginOpen}
          onlySingUp={onlySignUp}
          sid={sessionData.sid}
        />
      )}
    </>
  );
};

function Duration({ start, end }) {
  var startTime = moment(start);
  var endTime = moment(end);

  var hoursDiff = endTime.diff(startTime, "hours");
  // console.log("Hours:" + hoursDiff);

  // var minutesDiff = endTime.diff(startTime, "minutes");
  // console.log("Minutes:" + minutesDiff);

  // var secondsDiff = endTime.diff(startTime, "seconds");
  // console.log("Seconds:" + secondsDiff);
  return `${hoursDiff}h`;
}

export default PreLoginLiveSession;
