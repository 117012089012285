import React from "react";
import { makeStyles } from "@mui/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";

import config from "../../config/aws-exports";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { HeadingSecondary } from "../Styles/Heading";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    boxShadow: "none",
    paddingBottom: "0",
  },

  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
    paddingTop: "0",
    paddingBottom: "0 !important",
  },
  cover: {
    width: 151,
  },
  sizeLarge: {
    fontSize: "2.8rem",
  },
}));

export default function CourseCard({ title, tid }) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Card
      className={classes.root}
      style={{cursor:'pointer'}}
      onClick={() => {
        history.push(`/course?id=${tid}`)
        window.location.reload();
      } }
    >
      <CardMedia
        className={classes.cover}
        image={
          `https://${config.aws_cloudfront_url}/${config.aws_org_id.toLowerCase()}-resources/images/topic-images/${tid}.png`
        }
        title="Live from space album cover"
      />
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <HeadingSecondary
            component="h5"
            variant="h5"
            style={{ color: "#777", marginBottom: "2rem" }}
          >
            {title}
          </HeadingSecondary>
          <HeadingSecondary
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <AccessTimeIcon style={{ fontSize: "21px", marginRight: "8px" }} />
            <span>10h 20m</span>
          </HeadingSecondary>
        </CardContent>
      </div>
    </Card>
  );
}
