import React, { useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";

import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Theme } from "../../config/aws-exports";
import { Box } from "@mui/system";
import AppBarDashboard from "../../components/AppBar/AppBar.Dashboard";
import { HeadingPrimary } from "../../components/Styles/Heading";
import { Avatar, Divider, Grid } from "@mui/material";
import { authData } from "../../redux/auth/authSlice";
import { useSelector } from "react-redux";
import { Input } from "../../components/Styles/Input";
import { Container } from "../../components/Styles/Container";
import { API } from "aws-amplify";
import config from "../../config/aws-exports";
import { useQuery } from "react-query";

const Profile = () => {
  const userDetails = useSelector(authData);
  const [name, setName] = useState("");

  const breadcrumbs = [
    <Typography key="3" color={Theme.color.text}>
      My profile
    </Typography>,
  ];

  const { isLoading, error, data } = useQuery("profile", async () => {
    const bodyParam = {
      body: {
        emailid: userDetails?.email,
        schema: config.schema,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/getUserDetails",
        bodyParam
      );

      const res = response?.body[0];
      console.log(res);
      const name = `${res?.first_name} ${res?.last_name}`;
      setName(name);
      return response?.body[0];
    } catch (err) {
      console.error(err);
    }
  });
  return (
    <Stack spacing={2} margin={{ xs: 1, sm: 2, md: 3 }}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        sx={{ color: Theme.color.black }}
      >
        {breadcrumbs}
      </Breadcrumbs>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <HeadingPrimary>My profile</HeadingPrimary>
      </Stack>
      <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 4, md: 4 }}>
        <Avatar
          alt={name.toUpperCase() || ""}
          src="/static/images/avatar/1.jpg"
          sx={{ width: 160, height: 160, alignSelf: { xs: "center" } }}
        />
        <Stack
          direction="column"
          justifyContent="center"
          spacing={1}
          alignItems={{ xs: "center" }}
        >
          <Stack direction="row" alignItems="center" spacing={2}>
            <Box sx={{ minWidth: "80px", textAlign: "right" }}>Name :</Box>
            <Box>
              <Input
                readOnly
                value={name}
                style={{ borderColor: "#999999", textTransform: "capitalize" }}
              />
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" j spacing={2}>
            <Box sx={{ minWidth: "80px", textAlign: "right" }}>Email :</Box>
            <Box>
              <Input
                readOnly
                defaultValue={data && data?.emailid}
                sx={{ borderColor: "#999999" }}
              />
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Box sx={{ minWidth: "80px", textAlign: "right" }}>Ph. No :</Box>
            <Box>
              <Input readOnly defaultValue={data && data?.cno && data?.cno} />
            </Box>
          </Stack>
          {/* <Stack direction="row" alignItems="center" spacing={2}>
              <Box sx={{ minWidth: "80px", textAlign: "right" }}>
                Password :
              </Box>
              <Box>
                <Input />
              </Box>
            </Stack> */}
        </Stack>
      </Stack>

      <Divider />
    </Stack>
    // </Container>
  );
};

export default Profile;
