import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: [],
};

export const expressInterest = createSlice({
  name: "interests",
  initialState,
  reducers: {
    initialJobs: (state, action) => {
      state.value = action.payload;
    },
    addInterest: (state, action) => {
      state.value = [...state.value, action.payload];
    },
  },
});

// Action creators are generated for each case reducer function
export const { initialJobs, addInterest } = expressInterest.actions;

export default expressInterest.reducer;
