import styled from "styled-components";

export const HeadingPrimary = styled.div`
  font-family: inherit;
  font-weight: 600;
  /* font-size: 1.5vw; */
  font-size: 20px;
  cursor: text !important;
  /* margin: 0.2em; */
  /* @media screen and (max-width: 800px) {
    font-size: 18px;
  } */
`;
export const HeadingSecondary = styled.div`
  font-family: inherit;
  font-weight: 600;
  font-size: 16px;
  /* font-size: 1.2vw; */
  /* margin: 0.2em; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* text-transform: capitalize; */
  /* margin-bottom: 8px; */

  /* &:hover {
    color: ${({ theme }) => theme.color.secondary};
  } */
  @media screen and (max-width: 1024px) {
    font-size: 14px;
    white-space: normal;
    text-overflow: unset;
  }
`;
export const HeadingThree = styled.div`
  font-family: inherit;
  font-weight: 600;
  font-size: 16px;
  max-width: 500px;
  text-align: center;
  /* font-size: 1.2vw; */
  /* margin: 0.2em; */
  /* margin-bottom: 8px; */

  /* &:hover {
    color: ${({ theme }) => theme.color.secondary};
  } */
  @media screen and (max-width: 1024px) {
    font-size: 14px;
    white-space: normal;
    text-overflow: unset;
  }
`;
export const HeadingSecondaryLight = styled.div`
  font-family: inherit;
  font-weight: 400;
  font-size: 16px;
  text-transform: capitalize;
  transition: hover 1s ease-out;

  /* &:hover {
    color: ${({ theme }) => theme.color.secondary};
  } */
`;
