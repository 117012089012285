import React from "react";
import { Select, MenuItem } from '@mui/material';

const FeedbackItem = (props) => {
  const { format, questionNumber, fqi } = props;
  return (
    <div key={`fqi-${fqi}`}>
      {questionNumber === fqi + 1 && (
        <>
          {format === "multiple-choice-table" && (
            <MultipleChoiceTable {...props} />
          )}
          {format === "multiple-choice" && <MultipleChoice {...props} />}

          {format === "text" && <Text {...props} />}
          {format === "number" && <Number {...props} />}
          {format === "select" && <Selects {...props} />}
        </>
      )}
    </div>
  );
};

export default FeedbackItem;

function MultipleChoiceTable({
  heading,
  subHeading,
  questionNumber,
  questions,
  fqi,
  handleMultipleRadioChange,
}) {
  return (
    <div className="feedback-item-container">
      <h4>{heading}</h4>
      <p>{subHeading}</p>
      <table className="feedback-table">
        <thead>
          {questions[0]?.options?.map((question, index) => (
            <th key={`table-header-${index}`}>{question}</th>
          ))}
        </thead>
        <tbody>
          {questions.slice(1).map(({ options, question }, sqi) => (
            <tr key={`options-${sqi}`} className="radio-group">
              <td>{question}</td>
              {options.map((option, oi) => (
                <td key={`option-${oi}`} >
                  <input
                    className="radio-option"
                    type="radio"
                    style={{ width: '20px', height: '20px' }}
                    name={`radio-group-${fqi}-${sqi}`}
                    value={option}
                    required
                    onChange={(e) =>
                      handleMultipleRadioChange(
                        questionNumber,
                        sqi,
                        e.target.value
                      )
                    }
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

function MultipleChoice({
  heading,
  subHeading,
  question,
  options,
  fqi,
  questionNumber,
  handleRadioChange,
}) {
  return (
    <div className="feedback-item-container">
      <h4>{heading}</h4>
      <p>{subHeading}</p>
      <h4>{question}</h4>
      {options.map((option, oi) => (
        <div key={`option-${oi}`} style={{ display: 'flex' }}>
          <input
            type="radio"
            name={`radio-group-${fqi}`}
            id={`radio-group-${fqi}-${oi}`}
            style={{ width: '20px', height: '20px' }}
            value={option}
            required
            onChange={(e) => handleRadioChange(questionNumber, e.target.value)}
          />
          <label style={{ marginLeft: '5px', marginBottom: '5px' }} htmlFor={`radio-group-${fqi}-${oi}`}>{option}</label>
        </div>
      ))}
    </div>
  );
}

function Text({
  heading,
  subHeading,
  question,
  questionNumber,
  handleTextChange,
}) {
  return (
    <div className="feedback-item-container" style={{ marginTop: "10px" }}>
      <h4>{heading}</h4>
      <p>{subHeading}</p>
      <label>{question}</label>
      <textarea
        rows={3}
        style={{ width: "100%" }}
        required
        onChange={(e) => handleTextChange(questionNumber, e.target.value)}
      />
    </div>
  );
}

function Number({
  heading,
  subHeading,
  question,
  questionNumber,
  handleTextChange,
}) {
  return (
    <div className="feedback-item-container" style={{ marginTop: "10px", display: 'grid' }}>
      <h4>{heading}</h4>
      <p>{subHeading}</p>
      <label>{question}</label>
      <input
        type="number"
        min={0}
        style={{ width: "10%" }}
        required
        onChange={(e) => handleTextChange(questionNumber, e.target.value)}
      />
    </div>
  );
}

function Selects({
  heading,
  subHeading,
  question,
  questionNumber,
  handleTextChange,
}) {
  return (
    <div className="feedback-item-container" style={{ marginTop: "10px", display: 'grid' }}>
      <h4>{heading}</h4>
      <p>{subHeading}</p>
      <label>{question}</label>
      <Select
        labelId="my-select-label"
        label="Select an option"
        style={{ width: '100%' }}
        onChange={(e) => handleTextChange(questionNumber, e.target.value)}
        defaultValue={-1}
      >
        <MenuItem value={-1} disabled>Select USP Product</MenuItem>
        <MenuItem value="USP Education Programs">USP Education Programs</MenuItem>
        <MenuItem value="Reference Standards">Reference Standards</MenuItem>
        <MenuItem value="PAIs">PAIs</MenuItem>
        <MenuItem value="Other USP services">Other USP services</MenuItem>
      </Select>
    </div>
  );
}