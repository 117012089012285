
  import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { useSelector, useDispatch } from "react-redux";
import { authData, awsSignIn } from "../../../redux/auth/authSlice";
import config from "../../../config/aws-exports";
import CardSecondary from "../../../components/Card/Card.Secondary";

import { useHistory } from "react-router-dom";
import { Box, Breadcrumbs, Grid, Stack, Typography } from "@mui/material";
import { Skeleton } from "@mui/material";
import { useParams, Link, useLocation } from "react-router-dom";
import { session } from "../../../redux/Session/Session";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

export default function ViewAllCourses() {
  const userDetails = useSelector(authData);
  const [allCourses, setAllCourses] = useState([]);
  const [load, setLoad] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    getViewAllCourses();
    if (userDetails === 0) {
      window.location.href = config.aws_cloudfront_lurl;
    }
    console.log(JSON.stringify(userDetails));
  }, []);

  const getViewAllCourses = async () => {
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        tenant: userDetails.locale,
        schema: config.schema,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    //console.log(bodyParam);
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/getViewAllCourse",
        bodyParam
      );
      console.log(
        "stadaloone cousre",
        JSON.stringify(response.courses, null, 2)
      );
      const { courses } = response;
      const filterAllCourses = courses.filter(
        (course) =>
          course === undefined ||
          course.pid === undefined ||
          course.pid === "" ||
          course.pid === " " ||
          course.pid === "null " ||
          course.pid === null ||
          course.pid === 0
      );

      setAllCourses(filterAllCourses);

      setLoad(false);
    } catch (err) {
      console.error(err);
    }
  };
  const handleLocation = (TID, TN) => {
    const breadcrumb = { ...userDetails };
    breadcrumb.location = {
      from: "View All Courses",
      prevLoc: "course",
      courseName: TN,
      courseId: TID,
    };
    dispatch(awsSignIn(breadcrumb));
    history.push(`/course?id=${TID}`);
  };

  function ProgramView(pid) {
    if (userDetails.location.pFrom !== undefined) {
      return `/ExploreCourseView?from=0&id=${pid}`;
    } else {
      return `/ExploreCourseView?id=${pid}`;
    }
  }
  return (
    <div>
      {userDetails !== 0 &&
      userDetails !== undefined &&
      userDetails.location !== undefined ? (
        <>
          {userDetails.location.from !== undefined &&
          (userDetails.location.from === "Home" ||
            userDetails.location.from === "Explore" ||
            userDetails.location.from === "Search") ? (
            <Breadcrumbs
              separator={
                <NavigateNextIcon
                  fontSize="large"
                  style={{ fill: "#1974b5" }}
                />
              }
              aria-label="breadcrumb"
              style={{ marginBottom: "2rem", cursor: "pointer" }}
            >
              <Link
                color="inherit"
                to={userDetails.location.from === "Home" ? "/home" : "#"}
                style={{
                  color: `${
                    userDetails.location.from === "Home" ? "#1974b5" : "#777"
                  }`,
                  fontSize: "1.5rem",
                  textDecoration: "none",
                  cursor: `${
                    userDetails.location.from === "Home"
                      ? "pointer"
                      : "context-menu"
                  }`,
                }}
              >
                {userDetails.location.from}
              </Link>

              {userDetails.location.topicName && (
                <Link
                  to={
                    userDetails.location.courseName
                      ? `/explore/${userDetails.location.topicName.replaceAll(
                          /\s/g,
                          "-"
                        )}/${userDetails.location.topicId}`
                      : "#"
                  }
                  style={{
                    color: `${
                      userDetails.location.courseName ? "#1974b5" : "#777"
                    }`,
                    fontSize: "1.5rem",
                    textDecoration: "none",
                    cursor: `${
                      userDetails.location.courseName
                        ? "pointer"
                        : "context-menu"
                    }`,
                  }}
                  color="inherit"
                >
                  {userDetails.location.topicName}
                </Link>
              )}

              {userDetails.location.viewAll && (
                <Link
                  to={
                    userDetails.location.viewAllRoute
                      ? userDetails.location.viewAllRoute
                      : "/ExploreViewAll"
                  }
                  color="inherit"
                  style={{
                    color: "#1974b5",
                    fontSize: "1.5rem",
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                >
                  View all
                </Link>
              )}
              {userDetails.location.programName && (
                <Link
                  to={ProgramView(userDetails.location.programId)}
                  style={{
                    color: "#1974b5",
                    fontSize: "1.5rem",
                    textDecoration: "none",
                  }}
                  color="inherit"
                >
                  {userDetails.location.programName}
                </Link>
              )}

              {userDetails.location.courseName && (
                <Link
                  style={{
                    color: "#777",
                    fontSize: "1.5rem",
                    textDecoration: "none",
                    cursor: "context-menu",
                  }}
                  color="inherit"
                >
                  {userDetails.location.courseName}
                </Link>
              )}

              <Link
                style={{
                  color: "#777",
                  fontSize: "1.5rem",
                  textDecoration: "none",
                  cursor: "context-menu",
                }}
                color="inherit"
              >
                View All Courses
              </Link>
            </Breadcrumbs>
          ) : (
            ""
          )}
        </>
      ) : (
        <></>
      )}
      {load ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "1rem",
          }}
        >
          <Box>
            <Skeleton
              variant="rect"
              width={310}
              height={200}
              animation="wave"
            />
            <Box pt={0.5}>
              <Skeleton />
              <Skeleton width="60%" />
            </Box>
          </Box>
          <Box>
            <Skeleton
              variant="rect"
              width={310}
              height={200}
              animation="wave"
            />
            <Box pt={0.5}>
              <Skeleton />
              <Skeleton width="60%" />
            </Box>
          </Box>
          <Box>
            <Skeleton
              variant="rect"
              width={310}
              height={200}
              animation="wave"
            />
            <Box pt={0.5}>
              <Skeleton />
              <Skeleton width="60%" />
            </Box>
          </Box>
          <Box>
            <Skeleton
              variant="rect"
              width={310}
              height={200}
              animation="wave"
            />
            <Box pt={0.5}>
              <Skeleton />
              <Skeleton width="60%" />
            </Box>
          </Box>
        </div>
      ) : (
        <Grid container spacing={3} style={{ marginTop: "2rem" }}>
          {allCourses.length !== 0 ? (
            <>
             <Grid spacing={2} container>
          {allCourses?.map((item, idx) => {
            return (
              <Grid item xs={12} sm={6} md={4} lg={ 4}>
                  <CardSecondary
                    key={idx}
                    img={
                      item?.tid &&
                      `https://${
                        config.aws_cloudfront_url
                      }/${config.aws_org_id.toLowerCase()}-resources/images/topic-images/${
                        item?.tid
                      }.png`
                    }
                    title={item?.ttitle}
                    
                    handleLocation={() => {
                      history.push(`/course?id=${item?.tid}`);
                      dispatch(session({ ...item }));
                    }}
                    nonug={item?.noofnuggets}
                  />
              </Grid>
            );
          })}
        </Grid>
             
            </>
          ) : (
            <h3 style={{ textAlign: "center", margin: "10rem auto" }}>
              No course present
            </h3>
          )}
        </Grid>
      )}
    </div>
  );
}

