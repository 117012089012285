import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { CustomButton } from "../CustomButton/CustomButton";
import { Theme } from "../../config/aws-exports";
import logo from "../../assets/USP logo R.png";
import { useHistory } from "react-router-dom";

export default function AppBarLandingPage({ handleLoginClick }) {
  const history = useHistory();
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ backgroundColor: Theme.color.secondary }}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2, display: { xs: "none", sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <img
            src={logo}
            alt="logo"
            height="60"
            style={{ cursor: "pointer" }}
            onClick={() => {
              history.push("/");
            }}
          />
          <Box sx={{ flexGrow: 1 }} />
          <CustomButton onClick={handleLoginClick("login")} secondary>
            Login
          </CustomButton>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
