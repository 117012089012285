import { useState, useEffect } from "react";
import { API } from "aws-amplify";
import config from "../config/aws-exports";
function useFetch({ url, body }) {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchResponse() {
      setLoading("loading...");
      setData();
      setError(null);
      // const jwttoken = (await Auth.currentSession()).idToken.jwtToken;
      const bodyParam = {
        body: JSON.parse(body),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          // Authorization: jwttoken,
        },
      };
      try {
        console.log(JSON.stringify(url));
        const response = await API.post(
          config.aws_cloud_logic_custom_name,
          url,
          bodyParam
        );
        console.log(response);
        setLoading(false);
        setData(response);
        return;
      } catch (err) {
        setLoading(false);
        setError("An error occurred. Awkward..");
        console.error(err);
      }
    }
    fetchResponse();
  }, [url, body]);

  return { data, loading, error };
}
export default useFetch;
