import { Box, Breadcrumbs, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import CardPrimary from "../../components/Card/Card.Primary";
import { Container } from "../../components/Styles/Container";
import { HeadingPrimary } from "../../components/Styles/Heading";
import config, { Theme } from "../../config/aws-exports";
import { session } from "../../redux/Session/Session";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import AppBarDashboard from "../../components/AppBar/AppBar.Dashboard";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import CardSecondary from "../../components/Card/Card.Secondary";

import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

export default function ViewAll() {
  const { type, header, data } = useSelector(
    (state) => state.sessionViewAll.value
  );
  console.log({data})
  const dispatch = useDispatch();
  const history = useHistory();
  const breadcrumbs = [
    <Link to="/dashboard">Dashboard</Link>,
    <Typography key="3" color={Theme.color.text}>
      View all
    </Typography>,
  ];
  return (
    <>
      <Stack spacing={3} margin={{ xs: "1rem", sm: "3rem" }}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          sx={{ color: Theme.color.black }}
        >
          {breadcrumbs}
        </Breadcrumbs>
        <HeadingPrimary>{header}</HeadingPrimary>
        <Grid spacing={2} container>
          {data?.map((item, idx) => {
            return (
              <Grid item xs={12} sm={6} md={4} lg={type === "session" ? 3 : 4}>
                {type === "session" ? (
                  <CardPrimary
                    key={idx + "UKCsdf"}
                    auth={true}
                    title={item?.sn}
                    img={
                      item.sid &&
                      `https://${
                        config.aws_cloudfront_url
                      }/${config.aws_org_id.toLowerCase()}-resources/images/session-images/${
                        item.sid
                      }.png`
                    }
                    addToBookMarkList={() => {}}
                    addToFavorite={item.EI}
                    sessionType={item.stype}
                    sessionDate={moment(item?.date_list?.[0]?.date).format("DD MMM YYYY") === "invalid date"
                     ? 
                     "Yet to be Scheduled"
                    :moment(item?.date_list?.[0]?.date).format("DD MMM YYYY")
                    }
                    handleLocation={() => {
                      history.push(`/session?sid=${item.sid}`);
                      dispatch(session(item));
                    }}
                  />
                ) : (
                  <CardSecondary
                    key={idx}
                    img={
                      item?.tid &&
                      `https://${
                        config.aws_cloudfront_url
                      }/${config.aws_org_id.toLowerCase()}-resources/images/topic-images/${
                        item?.tid
                      }.png`
                    }
                    title={item?.ttitle}
                    sessionDate={
                      item.startdate &&
                      moment(parseInt(item.startdate)).format("DD MMM YYYY")
                    }
                    handleLocation={() => {
                      history.push(`/course?id=${item?.tid}`);
                      dispatch(session({ ...item }));
                    }}
                    nonug={item?.noofnuggets}
                  />
                )}
              </Grid>
            );
          })}
        </Grid>
      </Stack>
    </>
  );
}
