import { useState } from "react";
export const useForm = (initialState = {}) => {
  const [values, setValues] = useState(initialState);

  const handleChange = (prop) => (event) => {
    (prop === "name" || prop === "lname")
      ? setValues((values) => ({
          ...values,
          [prop]: event.target.value.trim(),
        }))
      : setValues((values) => ({
          ...values,
          [prop]: event.target.value,
        }));

      
  };

  const handleClickShowPassword = () => {
    setValues((values) => ({
      ...values,
      showPassword: !values.showPassword,
    }));
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return {
    values,
    setValues,
    handleChange,
    handleClickShowPassword,
    handleMouseDownPassword,
  };
};
