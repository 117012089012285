/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import "./Help.css";
import config from "../../config/aws-exports";
import styled from "styled-components";
import AppBarDashboard from "../../components/AppBar/AppBar.Dashboard";
import { Container } from "../../components/Styles/Container";

const Main = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding-bottom: 30px;
  div {
    margin-bottom: 30px;
  }
`;

const Help = () => {
  return (
    <Main>
      <h2> Help and support page</h2>
      <h3>Login</h3>
      <div>
        <p>
          1. Go to{" "}
          <a href="http://www.usp.enhanzed.com/"> www.usp.enhanzed.com</a>
        </p>
        <img
          src={`https://${
            config.aws_cloudfront_url
          }/${config.aws_org_id.toLowerCase()}-resources/images/cover-images/image6.png`}
          alt="image one"
        />
      </div>
      <div>
        <p>2. </p>
        <p>a. If you are a new user, click on the 'Sign up' button.</p>
        <p>
          b. Fill in the required details and click on the 'Sign up' button.
        </p>
        <img
          src={`https://${
            config.aws_cloudfront_url
          }/${config.aws_org_id.toLowerCase()}-resources/images/cover-images/image16.png`}
          alt="image two"
        />
        <h3 style={{ textAlign: "center" }}>OR</h3>
        <p>
          a. If you have already received your credentials through an email,
          click on the 'Login' button.
        </p>
        <p>
          b. Enter the username and passcode received in the email, click on the
          'Login' button.
        </p>
        <img
          src={`https://${
            config.aws_cloudfront_url
          }/${config.aws_org_id.toLowerCase()}-resources/images/cover-images/image1.png`}
          alt="image three"
        />
        <p>
          c. In the reset password page, enter a new password and confirm the
          new password.Click on the 'Login' button.
        </p>
      </div>
      <div>
        <p>
          3. Upon successful login, you will be able to see your dashboard with
          some popular / upcoming courses.
        </p>
        <img
          src={`https://${
            config.aws_cloudfront_url
          }/${config.aws_org_id.toLowerCase()}-resources/images/cover-images/image15.png`}
          alt="image four"
        />
      </div>
      <br />
      <h3>Registration</h3>
      <div>
        <p>
          1. On the dashboard, click on the course you want to register for.
        </p>
      </div>
      <div>
        <p>2. You will see the overview and more details about the course.</p>
        <img
          src={`https://${
            config.aws_cloudfront_url
          }/${config.aws_org_id.toLowerCase()}-resources/images/cover-images/image12.png`}
          alt="image five"
        />
      </div>
      <div>
        <p>3. </p>
        <p>
          a. If the course registration has already started, you can click on
          the 'Register' button.
        </p>
        <h3 style={{ textAlign: "center" }}>OR</h3>
        <p>
          a. Click on the 'Express interest' button to express your interest in
          the course.
        </p>
        <img
          src={`https://${
            config.aws_cloudfront_url
          }/${config.aws_org_id.toLowerCase()}-resources/images/cover-images/image12.png`}
          alt="image six"
        />
        <p>
          b. Once the registration starts for the course in which you have
          expressed interest, you will receive a notification.
        </p>
        <img
          src={`https://${
            config.aws_cloudfront_url
          }/${config.aws_org_id.toLowerCase()}-resources/images/cover-images/image7.png`}
          alt="image seven"
        />
        <p>c. Click on the course name from your dashboard.</p>
        <p>d. On the course details page click on the 'Register' button.</p>
        <img
          src={`https://${
            config.aws_cloudfront_url
          }/${config.aws_org_id.toLowerCase()}-resources/images/cover-images/image11.png`}
          alt="image eight"
        />
      </div>
      <div>
        <p>
          4. In the Registration details popup, please enter all the required
          details and click on the 'Next' button.
        </p>
        <img
          src={`https://${
            config.aws_cloudfront_url
          }/${config.aws_org_id.toLowerCase()}-resources/images/cover-images/image5.png`}
          alt="image nine"
        />
        <img
          src={`https://${
            config.aws_cloudfront_url
          }/${config.aws_org_id.toLowerCase()}-resources/images/cover-images/image4.png`}
          alt="image ten"
        />
      </div>
      <div>
        <p>
          5. You will see the payment details popup where you can choose 1 among
          the 3 payment methods.
        </p>
        <img
          src={`https://${
            config.aws_cloudfront_url
          }/${config.aws_org_id.toLowerCase()}-resources/images/cover-images/image17.png`}
          alt="image elevem"
        />
        <p>
          a. Direct payment: If you want to do direct payment to the USP bank
          account, you have to use the account number mentioned here. Once you
          do the payment, enter the acknowledgement number for the payment in
          the box provided.
        </p>
        <img
          src={`https://${
            config.aws_cloudfront_url
          }/${config.aws_org_id.toLowerCase()}-resources/images/cover-images/image.png`}
          alt="image twelve"
        />
        <p>
          b. Pay through Razor pay : Use this payment method if you want to pay
          through your debit card, credit card or UPI. Select whether you want
          to pay in INR or USD and click on the 'Next' button.
        </p>
        <img
          src={`https://${
            config.aws_cloudfront_url
          }/${config.aws_org_id.toLowerCase()}-resources/images/cover-images/image14.png`}
          alt="image thirteen"
        />
        <p>
          c. Pay later : You can use this option to do the payment later within
          the provided 'pay later date'. You also will have to add comments on
          how you would like to pay later so the USP admin can take a decision
          on your registration approval.
        </p>
        <img
          src={`https://${
            config.aws_cloudfront_url
          }/${config.aws_org_id.toLowerCase()}-resources/images/cover-images/image10.png`}
          alt="image fourteen"
        />
      </div>

      <div>
        <p>
          6. Once your payment is successful. The admin will have the ability to
          approve or reject your registration.
        </p>
      </div>
      <div>
        <p>
          7. Once the admin approves your registration, you will see the 'Join
          live session' button on your course details page. The 'Join live
          session' button will be enabled for your access only 10 mins prior to
          the start time of the session.
        </p>
        <img
          src={`https://${
            config.aws_cloudfront_url
          }/${config.aws_org_id.toLowerCase()}-resources/images/cover-images/image9.png`}
          alt="image fifteen"
        />
      </div>
      <div>
        <p>
          8. Once the 'Join live session' button is enabled, you can click on it
          to continue to the live session. The live session's link opens up as a
          new tab.
        </p>
      </div>
      <div>
        <p>
          9. Once you complete the session, please complete the feedback form by
          clicking on the 'Feedback' button on the top right corner of the page.
        </p>
        <img
          src={`https://${
            config.aws_cloudfront_url
          }/${config.aws_org_id.toLowerCase()}-resources/images/cover-images/image3.png`}
          alt="image sixteen"
        />
      </div>
      <div>
        <p>10. Please provide the feedback and click on the 'Submit' button.</p>
        <img
          src={`https://${
            config.aws_cloudfront_url
          }/${config.aws_org_id.toLowerCase()}-resources/images/cover-images/image8.png`}
          alt="image seventeen"
        />
      </div>
      <div>
        <p>
          11. Once admin marks your attendance, you will be able to view your
          certificate by clicking on the 'View certificate' button.
        </p>
        <img
          src={`https://${
            config.aws_cloudfront_url
          }/${config.aws_org_id.toLowerCase()}-resources/images/cover-images/image13.png`}
          alt="image seventeen"
        />
      </div>
    </Main>
  );
};
export default Help;
