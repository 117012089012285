import React, { useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";

import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Theme } from "../../config/aws-exports";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import AppBarDashboard from "../../components/AppBar/AppBar.Dashboard";
import { HeadingPrimary } from "../../components/Styles/Heading";
import config from "../../config/aws-exports";
import { authData } from "../../redux/auth/authSlice";
import { API } from "aws-amplify";
import { Divider, IconButton, Paper } from "@mui/material";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import Swal from "sweetalert2";
import { WrapperCalender } from "./Calendar.styles";

import moment from "moment";
import {
  Inject,
  ScheduleComponent,
  Day,
  Month,
  Week,
  WorkWeek,
} from "@syncfusion/ej2-react-schedule";

import "../../../node_modules/@syncfusion/ej2-base/styles/material.css";
import "../../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
import "../../../node_modules/@syncfusion/ej2-calendars/styles/material.css";
import "../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
import "../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
import "../../../node_modules/@syncfusion/ej2-lists/styles/material.css";
import "../../../node_modules/@syncfusion/ej2-navigations/styles/material.css";
import "../../../node_modules/@syncfusion/ej2-popups/styles/material.css";
import "../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
import "../../../node_modules/@syncfusion/ej2-react-schedule/styles/material.css";
import { Container } from "../../components/Styles/Container";

const Calendar = () => {
  const userDetails = useSelector(authData);
  const [eventsRec, setEvents] = useState([]);
  const [upEvents, setupEvents] = useState([]);

  const breadcrumbs = [
    <Typography key="3" color={Theme.color.text}>
      Calendar
    </Typography>,
  ];
  useEffect(() => {
    getEvents();

    return () => {};
  }, []);
  async function getEvents() {
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        tenant: userDetails.tenant,
        eid: userDetails.username,
        schema: config.schema,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      console.log(JSON.stringify(bodyParam.body));
      const events = await API.post(
        config.aws_cloud_logic_custom_name,
        "/getEvents",
        bodyParam
      );
      console.log("events", events);
      filter(events.events);

      //setLoading(false);
    } catch (error) {
      console.error(error);
    }
  }

  function filter(data) {
    let m = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let temp = [];
    for (let i = 0; i < data.length; i++) {
      let res = parseInt(data[i].start);
      let timestamp = new Date(res);
      let time = timestamp.getTime();
      let eventDate = timestamp.getDate();

      let seventhday = new Date().setDate(new Date().getDate() + 7);

      let d = new Date().getTime();
      let mon = new Date().getMonth();
      let yea = new Date().getFullYear();
      let date = new Date().getDate();
      let month = 0;
      let dd = timestamp.getDate();
      let mm = timestamp.getMonth() + 1;
      let yyyy = timestamp.getFullYear();

      month = mm;

      if (
        eventDate >= date &&
        time < seventhday &&
        parseInt(yyyy) == yea &&
        month >= mon &&
        parseInt(dd) >= date
      ) {
        let obj = {
          title: data[i].title,
          description: data[i].desc,
          start: parseInt(data[i].start),

          month: m[month],
          day: yyyy,
        };
        if (data[i].link != undefined && data[i].link != null) {
          obj.link = data[i].link;
        }
        temp.push(obj);
      }
    }
    setupEvents(temp);

    let cal = [];

    for (let i = 0; i < data.length; i++) {
      let res = new Date(parseInt(data[i].start));

      let dd = res.getDate();
      let mm = res.getMonth() + 1;
      let yyyy = res.getFullYear();

      let obj = {
        title: data[i].title,
        start: parseInt(data[i].start),
        eid: data[i].evid,
        description: data[i].desc,

        //description: 'Summer vacation planned for outstation.',
        startTime: new Date(parseInt(data[i].start)),
        endTime: new Date(parseInt(data[i].start)),
      };
      if (data[i].link != undefined) {
        obj.link = data[i].link;
      }

      cal.push(obj);
    }
    console.log("cal", cal);
    setEvents(cal);
  }
  let fields = {
    subject: { name: "title", default: "Event" },
    // description: { name: 'link' },
    url: { name: "link" },
    startTime: { name: "startTime" },
    endTime: { name: "endTime" },
  };

  function EventOpen(args) {
    args.cancel = true;
    Swal.fire({
      title: `${args.event.title}`,
      html: ` <div style={{ textAlign: "left" }}>
      <h3 class='mb-3'>
        Date & Time: ${moment(args.event.start).format("DD-MM-YYYY hh:mm a")}
      </h3>
      <p style={{ textAlign: "justify" }}>${args.event.description}</p>
      <a href=${args.event.link} target="_blank" rel="noreferrer">
      ${args.event.link}
      </a>
      <pre>
      </pre>
    </div>`,
      focusConfirm: false,
      confirmButtonText: "ok",
      confirmButtonColor: Theme.color.primary,
    });
    /*  swal({
      buttons: {
        cancel: {
          text: "Close",
          value: null,
          visible: true,
          className: "livesession__btn btn-color-c btn-size-1 btn-hov-c",
          closeModal: true,
        },

      },
      closeOnClickOutside: false,
      content: (
        <div style={{ textAlign: "left" }}>
          <h2 style={{ textAlign: "justify", marginBottom: "2rem" }}>
            {args.event.title}
          </h2>
          <h3>
            Date & Time: {moment(args.event.start).format("DD-MM-YYYY hh:mm a")}
          </h3>
          <p style={{ textAlign: "justify" }}>{args.event.description}</p>
          <a href={args.event.link} target="_blank" rel="noreferrer">
            {args.event.type !== "livesession" ? args.event.link : ""}
          </a>
        </div>
      ),
    }).then((willDelete) => {
      if (willDelete) {
        //handleNavigate(args.event);
      } else {
        // swal("Your imaginary file is safe!");
      }
    }); */
  }

  return (
    <>
      <Stack spacing={2} margin={{ xs: "1rem", sm: "3rem" }}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          sx={{ color: Theme.color.black }}
        >
          {breadcrumbs}
        </Breadcrumbs>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            {/*  <IconButton>
              <BookmarkIcon sx={{ color: "yellow", fontSize: "28px" }} />
            </IconButton> */}
          </Box>
        </Stack>
        <Box sx={{ display: "grid", placeItems: "center" }}>
          <Stack
            direction={{ xs: "column-reverse", md: "row" }}
            spacing={{ xs: 1, md: 4 }}
            maxWidth="1280px"
            justifyContent="center"
          >
            <Stack direction="column" gap="1rem">
              <HeadingPrimary>Calendar</HeadingPrimary>
              <Paper
                sx={{
                  p: 3,
                  borderRadius: "4px",
                  textAlign: "justify",
                  minWidth: "300px",
                  maxHeight: "580px",
                  boxShadow: Theme.boxShadow,
                }}
              >
                <ScheduleComponent
                  currentView="Month"
                  width="100%"
                  readonly={true}
                  eventClick={(e) => EventOpen(e)}
                  eventSettings={{
                    dataSource: eventsRec,
                    fields: fields,
                    enableTooltip: true,
                  }}
                >
                  <Inject services={[Month, Day, Week, WorkWeek]} />
                </ScheduleComponent>
              </Paper>
            </Stack>
            <Box sx={{ flexGrow: 1 }}>
              <HeadingPrimary>Upcoming Events</HeadingPrimary>
              <Box sx={{ height: "10px", mt: 0.5 }} />
              <Paper
                sx={{
                  p: 2,
                  backgroundColor: Theme.color.white,
                  minWidth: "400px",
                  boxShadow: Theme.boxShadow,
                }}
              >
                {upEvents.length === 0 ? (
                  <p style={{ fontSize: "15px", textAlign: "center" }}>
                    {" "}
                    No Upcoming Events
                  </p>
                ) : (
                  upEvents.map((event) => (
                    <>
                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={3}
                        sx={{ minHeight: 60 }}
                      >
                        <Box
                          sx={{
                            width: "40px",
                            maxHeight: "40px",
                            borderRadius: "3px",
                            boxShadow: Theme.boxShadow,
                          }}
                        >
                          <Stack
                            direction="row"
                            alignItems="flex-start"
                            sx={{
                              height: "20px",
                              width: "100%",
                              padding: "5px 10px",
                              fontSize: "12px",
                              backgroundColor: Theme.color.secondary,
                              borderRadius: "3px 3px 0 0",
                              color: "#fff",
                              textAlign: "center",
                            }}
                          >
                            <span style={{ marginBottom: "-10px" }}>
                              {" "}
                              {moment(event.start).format("ddd")}
                            </span>
                          </Stack>
                          <Stack
                            direction="row"
                            alignItems="flex-start"
                            sx={{
                              height: "20px",
                              width: "100%",
                              padding: "5px 10px",
                              fontSize: "12px",
                              borderRadius: "0 0 3px 3px",
                              textAlign: "center",
                            }}
                          >
                            <span style={{ marginLeft: "2px" }}>
                              {" "}
                              {moment(event.start).format("D")}
                            </span>
                          </Stack>
                        </Box>
                        <div>
                          <p>{event.title}</p>
                          <p>{event.description}</p>
                          <a
                            href={`${event.link}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {event.link}
                          </a>
                        </div>
                      </Stack>
                      <Divider />
                    </>
                  ))
                )}
              </Paper>
            </Box>
          </Stack>
        </Box>
      </Stack>
    </>
  );
};

export default Calendar;
