import { Breadcrumbs, Divider, Paper, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import AppBarDashboard from "../../components/AppBar/AppBar.Dashboard";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Container } from "../../components/Styles/Container";
import { Theme } from "../../config/aws-exports";
import { HeadingPrimary } from "../../components/Styles/Heading";
import { Wrapper } from "./Notifications.styles";
import { Box } from "@mui/system";
import DeleteIcon from "@mui/icons-material/Delete";
// import useFetch from "../../Hooks/useFetch";
import config from "../../config/aws-exports";
// import gif from '../../assets/giphy.gif';

import { useSelector } from "react-redux";
import { authData } from "../../redux/auth/authSlice";
import { useHistory } from "react-router-dom";
import moment from "moment";
import Swal from "sweetalert2";
import API from "@aws-amplify/api";
import { useQuery } from "react-query";
import emptysearch from "../../assets/Nothing here yet.gif";

const Notifications = () => {
  const userDetails = useSelector(authData);
  const history = useHistory();
  const { data, isLoading, error, refetch } = useQuery(
    "notifications",
    async () => {
      try {
        // const id = await parseInt(window.localStorage.getItem("ur_id"));
        const res = await API.post(
          config.aws_cloud_logic_custom_name,
          "/getNotifications",
          {
            body: {
              oid: config.aws_org_id,
              eid: userDetails.username,
              tenant: userDetails?.uData.oid,
              ur_id: userDetails?.uData?.ur_id,
              schema: config.schema,
            },
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              // Authorization: jwttoken,
            },
          }
        );

        return res;
      } catch (err) {
        console.error(err.message);
      }
    }
  );

  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    if (data) {
      console.log("notifications==", data);
      const { notifications } = data;
      const decNotification = notifications.sort((a, b) => {
        const aTime = parseInt(a.time);
        const bTime = parseInt(b.time);
        console.log(aTime);
        console.log(bTime);
        return bTime - aTime;
      });
      console.log(decNotification);
      setNotifications(decNotification);
    }
  }, [data]);

  async function notificationStatus(msg, type) {
    if (type === "message") {
      if (msg.type === 1) {
        history.push(`/course?id=${msg.tid}`);
      } else if (msg.type === 2) {
        window.open(msg.link, "_blank");
      } else {
        Swal.fire({
          html: `
          <div class='notify'>
          <div class='max-w'>
          <h5 class='md'>${msg.title.substring(0, 80)}${
            msg.title.length > 80 ? "..." : ""
          }</h5>
          </div>
          <p>${msg.msg}</p>
          <a href='${msg.link}' target='_black'>${msg.link ?? ""}</a>
          </div>
          <style>
          .max-w{max-width: 450px; text-align: center}
          .notify{display: flex;flex-direction: column; gap: 1rem}
          .md{font-size: 22px;}
          </style>
          `,
          showConfirmButton: true,
          confirmButtonColor: Theme.color.primary,
          confirmButtonText: "Ok",
        });
      }
      setNotifications((prev) => {
        return prev.map((notification) =>
          msg.nt_id === notification.nt_id
            ? { ...notification, read: 1 }
            : { ...notification }
        );
      });
    } else {
      setNotifications((prev) => {
        return prev.filter((notification) => msg.nt_id !== notification.nt_id);
      });
    }

    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        eid: userDetails.username,
        tenant: userDetails.tenant,
        nt_id: msg.nt_id,
        ur_id: userDetails?.uData?.ur_id,
        time: msg.time,
        type: type,
        schema: config.schema,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    if (type === "delete") bodyParam.body.type = "delete";

    console.log(JSON.stringify(bodyParam.body, null, 2));
    try {
      await API.post(
        config.aws_cloud_logic_custom_name,
        "/postNotificationStatusWeb",
        bodyParam
      );

      refetch();
    } catch (err) {
      console.error(err);
    }
  }

  const breadcrumbs = [
    <Typography key="3" color={Theme.color.text}>
      Notifications
    </Typography>,
  ];
  if (error) return error.message;

  return (
    <Wrapper>
      <Stack spacing={2} margin={{ xs: 1, sm: 2, md: 3 }}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          sx={{ color: Theme.color.black }}
        >
          {breadcrumbs}
        </Breadcrumbs>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <HeadingPrimary>Notifications</HeadingPrimary>
        </Stack>

        <Paper sx={{ boxShadow: Theme.boxShadow, mb: 4 }}>
          {notifications?.map((notification) => (
            <Stack direction="column" sx={{ px: 2 }}>
              <Stack direction="row">
                <Box sx={{ width: 100, height: "max-content" }}>
                  <Box
                    sx={{
                      color: Theme.color.primary,
                      fontWeight: "bold",
                      width: "100px",
                    }}
                  >
                    {notification.read === 0 ? "New" : ""}
                  </Box>
                </Box>
                <Stack
                  direction="column"
                  sx={{ m: 2, flexGrow: 1, cursor: "pointer" }}
                  spacing={2}
                  onClick={() => notificationStatus(notification, "message")}
                >
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Box component="h4" sx={{ m: 0, p: 0 }}>
                      {notification.title}
                    </Box>{" "}
                    <span>
                      {notification.time !== undefined &&
                        notification.time !== null &&
                        moment(parseInt(notification.time)).format(
                          "DD MMM YYYY"
                        )}
                    </span>
                  </Stack>
                  <Box>{notification.msg}</Box>
                </Stack>
                <Stack direction="column" sx={{ m: 2 }} spacing={2}>
                  <DeleteIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => notificationStatus(notification, "delete")}
                  />
                </Stack>
              </Stack>
              <Divider className="divider" sx={{ borderColor: "#333" }} />
            </Stack>
          ))}
        </Paper>
        {data?.notifications.length === 0 && (
          <div
            style={{
              height: "60vh",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <img src={emptysearch} alt="emptysearch" />
          </div>
        )}
      </Stack>
    </Wrapper>
  );
};

export default Notifications;
