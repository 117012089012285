import { Container } from "@mui/material";
import React from "react";
import AppBarDashboard from "../AppBar/AppBar.Dashboard";

const Layout = ({ children }) => {
  return (
    <div>
      <AppBarDashboard />
      <main style={{ marginTop: "95px", paddingBottom: "1.5rem" }}>
        <Container maxWidth="xl">{children}</Container>
      </main>
    </div>
  );
};

export default Layout;
