import { Stack } from "@mui/material";

import styled from "styled-components";

export const Wrapper = styled(Stack)`
  .mb-3 {
    margin-bottom: 2rem;
  }
`;
export const WrapperCalender = styled.div`
  .titleStyle {
    font-size: 18px;
    font-weight: bold;
    padding-bottom: 30px;
  }
  .eventsDivider {
    margin-top: 20px;
  }

  .iconBottom {
    border: none;
    float: left;
    margin-top: 14px;
    margin-left: -60px;
    width: 40px;
    background-color: white;
    height: 25px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    font-size: medium;
    box-shadow: 2px 2px 2px grey;
    margin-right: 20px;
    text-align: center;
    //position: fixed;
  }

  .iconTop {
    font-size: 12px;
    font-weight: bold;
    //position: fixed;
    border: 1px solid;
    float: left;
    margin-top: -5px;
    width: 40px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    background-color: #f36045;
    color: white;
    margin-right: 20px;
    text-align: center;
    box-shadow: 2px 2px 2px grey;
  }
`;
