import { useEffect, useMemo, useState } from "react";

import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
//Icons
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircle from "@mui/icons-material/AccountCircle";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import BookmarkBorderRoundedIcon from "@mui/icons-material/BookmarkBorderRounded";
import FavoriteBorderRoundedIcon from "@mui/icons-material/FavoriteBorderRounded";
import DateRangeRoundedIcon from "@mui/icons-material/DateRangeRounded";
import config from "../../config/aws-exports";
import DateRangeTwoToneIcon from "@mui/icons-material/DateRangeTwoTone";

import Avatar from "@mui/material/Avatar";

// Drawer
import Drawer from "@mui/material/Drawer";
//List
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
//styles
import "./AppBar.css";
import { Theme } from "../../config/aws-exports";
import { Container, LinearProgress, Menu, MenuItem } from "@mui/material";
import MoreIcon from "@mui/icons-material/MoreVert";
import { useDispatch, useSelector } from "react-redux";
import { awsSignOut } from "../../redux/auth/authSlice";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Auth, API } from "aws-amplify";
import { awsSignIn, authData } from "../../redux/auth/authSlice";
import useFetch from "../../Hooks/useFetch";
import logo from "../../assets/USP logo R.png";
import { Favorite, Notifications } from "@mui/icons-material";
import useDebounce from "../../Hooks/useDebounce";
import Highlighter from "react-highlight-words";
import { useQuery } from "react-query";
import { red } from "@mui/material/colors";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const SearchItem = styled(Link)(({ theme }) => ({
  // padding: theme.spacing(0, 2),
  padding: ".5rem .5rem",
  listStyle: "none",
  cursor: "pointer",
  width: "100%",
  "&:hover": {
    backgroundColor: "#f5f5f5",
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "25ch",
    },
  },
}));

const renderIcon = (obj) => {
  switch (obj.icon) {
    case "heart":
      return <FavoriteBorderRoundedIcon color="inherit" />;
    case "notify":
      return <NotificationsOutlinedIcon color="inherit" />;
    case "calender":
      return <DateRangeRoundedIcon color="inherit" />;
    case "bookmark":
      return <BookmarkBorderRoundedIcon color="inherit" />;
    default:
      return;
  }
};
export default function AppBarDashboard({ loading }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchVal, setSearchVal] = useState("");
  const [fliteredSearch, setFilteredSearch] = useState([]);
  const interests = useSelector((state) => state.interests.value);
  const deboundedValue = useDebounce(searchVal, 1000);
  const userDetails = useSelector(authData);
  const data = useFetch({
    url: "/getNotifications",
    body: JSON.stringify({
      oid: config.aws_org_id,
      eid: userDetails.username,
      tenant: userDetails?.uData?.oid,
      ur_id: userDetails?.uData?.ur_id,
      schema: config.schema,
    }),
  });
  console.log({ data });

  const unreadMsgs = useMemo(() => {
    const res = data?.data?.notifications?.filter((n) => n?.read === 0);
    return res;
  }, [data?.data?.notifications]);

  const {
    data: listBookMarkData,
    error,
    isLoading,
  } = useQuery("/bookMarkList", async () => {
    const bodyParam = {
      body: {
        ur_id: userDetails?.uData?.ur_id,
        schema: config.schema,
        tenant: userDetails.tenant,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    console.log(bodyParam);
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/getMyInterests",
        bodyParam
      );
      const { items } = response;
      console.log("list", items);
      const bookmarkList = items.filter((item) => item.type === 1);
      return bookmarkList;
    } catch (err) {
      console.error(err);
    }
  });
  useEffect(() => {
    window.scroll(0, 0);
    async function getMyCourse() {
      // setLoading(true);
      const jwttoken = (await Auth.currentSession()).idToken.jwtToken;
      const bodyParam = {
        body: {
          oid: config.aws_org_id,
          searchTerm: deboundedValue,
          schema: config.schema,
          tenant: userDetails?.tenant,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: jwttoken,
        },
      };
      try {
        const response = await API.post(
          config.aws_cloud_logic_custom_name,
          "/searchCourse ",
          bodyParam
        );
        let filter = response.search.slice(0, 10);
        setFilteredSearch(filter);
      } catch (err) {
        // setLoading(false);
        //setError("An error occurred. Awkward..");
        console.error(err);
      }
      // const { course, session } = data;
    }

    getMyCourse();
  }, [deboundedValue, userDetails?.tenant]);

  const [badges, setBadges] = useState({
    notifications: null,
    bookmark: null,
    wishlist: null,
  });

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const { pathname } = useLocation();

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };
  const handleSearch = async () => {
    let sdata = { ...userDetails };
    sdata.searchVal = searchVal;
    dispatch(awsSignIn(sdata));
    history.push(`/results?search_query=${encodeURIComponent(searchVal)}`);
    setSearchVal("");
  };
  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      id={menuId}
      keepMounted
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem
        sx={{ mx: 2 }}
        onClick={() => {
          history.push("/profile");
          // handleMenuClose();
        }}
      >
        <p>Profile</p>
      </MenuItem>

      <MenuItem sx={{ mx: 2 }} onClick={() => history.push("/certificates")}>
        <p>Certificates</p>
      </MenuItem>
      <MenuItem sx={{ mx: 2 }} onClick={() => history.push("/invoices")}>
        <p>Proforma Invoices</p>
      </MenuItem>
      <MenuItem sx={{ mx: 2 }} onClick={() => history.push("/helpandsupport")}>
        <p>Help & Support</p>
      </MenuItem>
      <MenuItem
        sx={{ mx: 2 }}
        onClick={async () => {
          try {
            await Auth.signOut();
            dispatch(awsSignOut());
            history.push("/");
          } catch (error) {
            history.push("/");
            console.log("error signing out: ", error);
          }
        }}
      >
        <p>Sign out</p>
      </MenuItem>
    </Menu>
  );
  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      id={mobileMenuId}
      keepMounted
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem
        sx={{ mx: 2 }}
        onClick={() => {
          history.push("/profile");
          handleMobileMenuClose();
        }}
      >
        <p>Profile</p>
      </MenuItem>

      <MenuItem
        sx={{ mx: 2 }}
        onClick={() => {
          history.push("/certificates");
          handleMobileMenuClose();
        }}
      >
        <p>Certificates</p>
      </MenuItem>
      <MenuItem
        sx={{ mx: 2 }}
        onClick={() => {
          history.push("/invoices");
          handleMobileMenuClose();
        }}
      >
        <p>Proforma Invoices</p>
      </MenuItem>
      <MenuItem
        sx={{ mx: 2 }}
        onClick={async () => {
          try {
            await Auth.signOut();
            dispatch(awsSignOut());
            history.push("/");
          } catch (error) {
            history.push("/");
            console.log("error signing out: ", error);
          }
        }}
      >
        <p>Sign out</p>
      </MenuItem>
    </Menu>
  );

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 350,
        flexGrow: 1,
        position: "relative",
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Box
        sx={{
          height: "200px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Avatar
          alt={userDetails?.name}
          src="/static/images/avatar/1.jpg"
          sx={{ width: 56, height: 56 }}
        />
        <Typography variant="h5" gutterBottom component="div">
          {userDetails !== 0 && userDetails?.uData
            ? `${userDetails?.uData?.first_name} ${userDetails?.uData?.last_name}`
            : ""}
        </Typography>
        <Typography variant="h7" gutterBottom component="div">
          {userDetails?.email}
        </Typography>
      </Box>
      <Divider />
      <List>
        {[
          { text: "Notifications", icon: "notify" },
          { text: "Wishlist", icon: "heart" },
          { text: "Calendar", icon: "calender" },
        ].map((obj, index) => (
          <ListItem
            button
            key={obj.text}
            className="ListItem"
            onClick={() => history.push(`/${obj.text.toLowerCase()}`)}
          >
            <ListItemIcon className="ListItemIcon">
              {renderIcon(obj)}
            </ListItemIcon>
            <ListItemText primary={obj.text} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
  return (
    <Box sx={{ flexGrow: 1, position: "relative" }}>
      <AppBar
        position="static"
        sx={{
          backgroundColor: Theme.color.secondary,
          top: 0,
          position: "fixed",
          zIndex: 1000,
          transition: "150ms ease-out",
        }}
      >
        {loading && (
          <LinearProgress sx={{ color: Theme.color.secondaryLight }} />
        )}
        <Container maxWidth="xl">
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              sx={{ mr: 2, display: { xs: "block", sm: "none" } }}
              onClick={toggleDrawer("left", true)}
            >
              <MenuIcon />
            </IconButton>
            <img
              src={logo}
              alt="USP logo"
              height="55"
              style={{ cursor: "pointer" }}
              onClick={() => {
                history.push("/dashboard");
              }}
            />
            <Box sx={{ flexGrow: 1 }} />
            <div style={{ position: "relative", border: " #999999" }}>
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search…"
                  inputProps={{ "aria-label": "search" }}
                  value={searchVal}
                  onChange={(event) => setSearchVal(event.target.value)}
                  onKeyPress={(e) => e.key === "Enter" && handleSearch()}
                />
              </Search>
              {fliteredSearch.length > 0 && (
                <div
                  style={{
                    position: "absolute",
                    top: "40px",
                    left: 25,
                    minWidth: "300px",
                    maxHeight: "60vh",
                    background: "white",
                    color: "black",
                    boxShadow: Theme.boxShadow,
                    borderRadius: "5px",
                  }}
                >
                  <>
                    {fliteredSearch.map((item, index) => {
                      let href;
                      if (item.itype === 1) {
                        href = `/session?sid=${item.sid}`;
                      } else {
                        href = `/course?id=${item.sid}`;
                      }
                      return (
                        <Box
                          key={item?.sid}
                          onClick={() => {
                            history.push(href);
                          }}
                          sx={{ p: 1, cursor: "pointer" }}
                          className="search-results"
                        >
                          <Highlighter
                            highlightClassName="YourHighlightClass"
                            searchWords={deboundedValue.split(" ")}
                            autoEscape={true}
                            textToHighlight={item?.sn}
                          />
                        </Box>
                      );
                    })}
                  </>
                </div>
              )}
            </div>
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <IconButton
                size="large"
                aria-label="show 4 new mails"
                color="inherit"
                onClick={() => history.push("/calendar")}
              >
                {pathname === "/calender" ? (
                  <DateRangeTwoToneIcon />
                ) : (
                  <DateRangeRoundedIcon />
                )}
              </IconButton>
              <IconButton
                size="large"
                aria-label="show 4 new mails"
                color="inherit"
                onClick={() => history.push("/wishlist")}
              >
                {pathname === "/wishlist" ? (
                  <Favorite />
                ) : (
                  <FavoriteBorderRoundedIcon />
                )}
              </IconButton>
              {/* <IconButton
                size="large"
                aria-label="show 4 new mails"
                color="inherit"
                onClick={() => history.push("/bookmark")}
              >
                <Badge badgeContent={listBookMarkData?.length} color="error">
                  <BookmarkBorderRoundedIcon />
                </Badge>
              </IconButton> */}
              <IconButton
                size="large"
                aria-label="show 17 new notifications"
                color="inherit"
                onClick={() => history.push("/notifications")}
              >
                <Badge badgeContent={unreadMsgs?.length} color="error">
                  {pathname === "/notifications" ? (
                    <Notifications />
                  ) : (
                    <NotificationsOutlinedIcon />
                  )}
                </Badge>
              </IconButton>
              <Box sx={{ display: "flex", alignItems: "center", ml: 1.5 }}>
                <Typography
                  variant="h7"
                  component="div"
                  sx={{
                    display: {
                      xs: "none",
                      sm: "block",
                      textTransform: "capitalize",
                    },
                  }}
                >
                  {userDetails?.uData?.first_name +
                    " " +
                    userDetails?.uData?.last_name}
                </Typography>
                <IconButton
                  size="large"
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
              </Box>
            </Box>
            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}

      <Drawer
        anchor="left"
        open={state["left"]}
        onClose={toggleDrawer("left", false)}
      >
        {list("left")}
      </Drawer>
    </Box>
  );
}
