import * as React from "react";

import { Theme } from "../../config/aws-exports";

import { Button, IconButton, LinearProgress, Stack, Tooltip } from "@mui/material";
import { Box } from "@mui/system";

import { HeadingSecondary } from "../Styles/Heading";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import styled from "styled-components";

export const StyledSecondaryCardGrid = styled(Box)`
  display: flex;
  max-width: 450px;
  margin: 1rem 0.5rem;
  border-radius: 5px;
  box-shadow: ${Theme.boxShadow};
  cursor: pointer;

  .left {
    /* background-color: red; */
    max-width: 190px;
    height: 150px;
    img {
      width: 100%;
      height: 100%;

      border-radius: 5px 0 0 5px;
    }
  }
  .right {
    width: 100%;
    height: 150px;

    .mod-c {
      height: 30%;
      padding: 0.5rem 1rem;
      .mod {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        /* min-width: 190px; */
      }
    }
    .para-container {
      height: 70%;
      padding: 0.5rem 1rem;
      p {
        display: -webkit-box;
        max-width: 345px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        font-weight: 600;
      }
    }
  }
`;

const CardSecondary = ({ img, handleLocation, title, nonug, ...rest }) => (
  <StyledSecondaryCardGrid>
    <Box className="left" onClick={handleLocation}>
      <img src={img} alt={title} height="150" width="auto" />
    </Box>
    <Box className="right">
      <div className="para-container" onClick={handleLocation}>
        <p>{title}</p>
      </div>
      {/* <Tooltip title={title} placement="top-start">
        <p className="para-container">{title}</p>
      </Tooltip> */}
      <Box className="mod-c">
        {!rest.bookmark && (
          <Box className="mod">
            <Box>{nonug && nonug}</Box>
            <Box>Modules</Box>
          </Box>
        )}
        {rest.bookmark && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>Date : {rest.date}</Box>
            <Box>
              <IconButton onClick={rest.handleDelete}>
                <BookmarkIcon sx={{ fill: "yellow" }} />
              </IconButton>
            </Box>
          </Stack>
        )}
      </Box>
    </Box>
  </StyledSecondaryCardGrid>
);

export default CardSecondary;
