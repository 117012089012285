import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { IconButton } from "@mui/material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
// import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import { Box } from "@mui/system";
import { HeadingSecondary } from "../Styles/Heading";
import { Theme } from "../../config/aws-exports";
import config from "../../config/aws-exports";

import { Favorite } from "@mui/icons-material";
import { Status } from "../Styles/Status";
import { styled } from "@mui/material/styles";

import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));
export const CardPrimary = ({
  auth,
  title,
  addToFavorite,
  addToBookMarkList,
  imageId,
  tenant,
  img,
  handleLocation,
  sessionType,
  sessionDate,
}) => (
  <Card
    sx={{
      boxShadow: Theme.boxShadow,
      m: 0.5,
      textTransform: "capitalize",
      cursor: "pointer",
      maxWidth: 345,
      position: "relative",
    }}
    onClick={handleLocation}
  >
    <Status component="span" type={sessionType && sessionType}>
      {sessionType === 1 && "Live web"}
      {sessionType === 2 && "Classroom"}
      {sessionType === 3 && "hybrid"}
    </Status>
    <CardMedia
      component="img"
      height="175"
      image={`${
        imageId && tenant
          ? `https://${config.aws_cloudfront_url}/usp-resources/images/session-images/${imageId}.png`
          : img
      }`}
      alt={title}
    />
    <CardContent style={{ paddingBottom: "10px" }}>
      <LightTooltip title={title} placement="top-start">
        <HeadingSecondary
          style={{
            maxHeight: "50px",
            maxWidth: "100%",
            p: 1,
          }}
        >
          {title}
        </HeadingSecondary>
      </LightTooltip>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          gutterBottom
          variant="h8"
          component="p"
          sx={{ overflow: "hidden", maxHeight: "40px" }}
        >
          {sessionDate && sessionDate}
        </Typography>
        {auth && (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {!addToFavorite ? (
              <FavoriteBorderIcon />
            ) : (
              <Favorite sx={{ fill: "red" }} />
            )}
            {/* <IconButton onClick={addToBookMarkList}>
              <BookmarkBorderOutlinedIcon />
            </IconButton> */}
          </Box>
        )}
      </Box>
    </CardContent>
  </Card>
);

export default CardPrimary;
