import { applyMiddleware, createStore } from "redux";
import { persistStore } from "redux-persist";
import logger from "redux-logger";
import rootReducers from "./rootReducers";
import thunk from "redux-thunk";

const middleware = [logger, thunk].filter(Boolean);

const store = createStore(rootReducers, applyMiddleware(...middleware));
const persister = persistStore(store);

export { store, persister };
