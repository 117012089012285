import { useState, useEffect } from "react";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";

import Box from "@mui/material/Box";
import { Theme } from "../../config/aws-exports";
import { HeadingSecondary } from "../Styles/Heading";

export function CircularProgressWithLabel(props) {
  return (
    <Box
      sx={{
        position: "relative",
        display: "inline-flex",
        height: 120,
        width: 120,
      }}
    >
      <CircularProgress
        variant="determinate"
        sx={{
          color: Theme.color.secondaryLight,
        }}
        size={120}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) =>
            theme.palette.mode === "light"
              ? Theme.color.secondary
              : Theme.color.secondary,
          animationDuration: "550ms",
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        size={120}
        thickness={4}
        {...props}
      />
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <HeadingSecondary>{`${
          isNaN(props.value) ? 0 : Math.round(props.value)
        }%`}</HeadingSecondary>
      </Box>
    </Box>
  );
}

export default function CustomCircularProgress({
  courseDetails,
  totalProgress,
}) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    console.log(courseDetails);

    if (
      courseDetails?.userdataset?.userdata === true ||
      courseDetails?.userdataset?.userdata === "true"
    ) {
      if (
        courseDetails?.userdataset?.tp !== undefined &&
        (courseDetails?.userdataset?.tp.charAt(0) === "2" ||
          courseDetails?.userdataset?.tp.charAt(0) === "3")
      ) {
        setProgress(100);
      } else {
        let cobj = totalProgress === undefined ? 0 : totalProgress;
        let tscore = (cobj / courseDetails?.tobj) * 100;
        console.log("tscore==",tscore)
        setProgress(tscore >= 100 ? 100 : tscore);
      }
    }
  }, [courseDetails, totalProgress]);

  return <CircularProgressWithLabel value={progress} />;
}
