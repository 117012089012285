import { Stack } from "@mui/material";

import styled from "styled-components";

export const Wrapper = styled(Stack)``;
export const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 3fr 420px;
  /* grid-template-rows: repeat(12, 1fr); */
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;

  .item-1 {
    grid-column: 1/2;
    grid-row: 1/2;
    height: max-content;
    @media (max-width: 750px) {
      grid-column: 1/-1;
      grid-row: 7/10;
    }
  }

  .item-2 {
    grid-column: 2 / -1;
    grid-row: ${({ layout }) => layout || "1 / 8"};
    height: max-content;
    @media (max-width: 750px) {
      grid-column: 1/-1;
      grid-row: ${({ layout }) => (layout === "2/10" ? "2/7" : "1 / 7")};
    }
  }
  .item-3 {
    grid-column: 1/2;
    grid-row: 2/3;
    height: max-content;
    @media (max-width: 750px) {
      grid-column: 1/-1;
      grid-row: 10/12;
    }
  }
  .item-4 {
    height: max-content;
    @media (max-width: 750px) {
      grid-column: 1/-1;
      grid-row: 1/2;
    }
  }

  .object-text {
    color: ${({ theme }) => theme.color.secondary};
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .overview {
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    // align-items: center;
  }
  .overview-left {
    p {
      text-align: justify;
      margin-bottom: 10px;
    }
  }
  .headp {
    font-weight: bold;
  }
  .overview-right {
    table {
      .head {
        font-weight: bold;
      }
    }
  }
`;
