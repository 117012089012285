import {
  Backdrop,
  Breadcrumbs,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import AppBarDashboard from "../../components/AppBar/AppBar.Dashboard";
// import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Container } from "../../components/Styles/Container";
import { Theme } from "../../config/aws-exports";
import { HeadingPrimary } from "../../components/Styles/Heading";
import { cardSettings, cardSecondarySettings } from "../Home/SlickSettings";
import CardSecondary from "../../components/Card/Card.Secondary";
import { Wrapper } from "./Search.styles";
import { Box } from "@mui/system";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "../../components/Styles/Input";
import CardPrimary from "../../components/Card/Card.Primary";
import Slider from "react-slick";
import { authData } from "../../redux/auth/authSlice";
import { API, Auth } from "aws-amplify";
import config from "../../config/aws-exports";
import { useQueryParams } from "../../Hooks/useQueryParams";
import moment from "moment";
import { sessionAll } from "../../redux/viewAll/viewAll";
import { CusLink } from "../Dashboard/Dashboard";
import emptysearch from "../../assets/Nothing here yet.gif";

const Search = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const userDetails = useSelector(authData);
  const query = useQueryParams();
  const [myCourses, setMyCourses] = useState();
  const [session, setSession] = useState();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    window.scroll(0, 0);
    async function getMyCourse() {
      setLoading(true);
      const jwttoken = (await Auth.currentSession()).idToken.jwtToken;
      const bodyParam = {
        body: {
          oid: config.aws_org_id,
          searchTerm: query.get("search_query"),
          schema: config.schema,
          tenant: userDetails?.tenant,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: jwttoken,
        },
      };
      try {
        const response = await API.post(
          config.aws_cloud_logic_custom_name,
          "/searchCourse ",
          bodyParam
        );
        //setLoading(false);
        setSession([]);
        setMyCourses([]);
        if (response.search !== undefined) {
          var session = [],
            course = [];

          for (var k = 0; k < response.search.length; k++) {
            if (response.search[k]?.itype === 1) {
              session.push(response.search[k]);
            } else {
              var data = response.search[k];
              var obj = {};
              obj.tid = data?.sid;
              obj.noofnuggets = data?.nugcnt;
              obj.ttitle = data?.tn;
              // obj.startdate = data.
              course.push(obj);
            }
          }
          setSession(session);
          setMyCourses(course);
        }
        setLoading(false);
        console.log(response);
      } catch (err) {
        setLoading(false);
        //setError("An error occurred. Awkward..");
        console.error(err);
      }
      // const { course, session } = data;
    }
    getMyCourse();
  }, [query.get("search_query")]);
  // const handleSessionViewAll = () => {
  //   history.push("/allCourses");
  // };
  const mSession = session !== undefined &&
    session.length > 0 &&
    userDetails && (
      <Box sx={{ m: { xs: 1, sm: 2, md: 3 } }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <HeadingPrimary>Session</HeadingPrimary>
          {session.length > 10 && (
            <CusLink
              to="/viewall"
              onClick={() => {
                dispatch(
                  sessionAll({
                    type: "session",
                    header: "Session",
                    data: session,
                  })
                );
                history.push("/viewall");
              }}
            >
              View all
            </CusLink>
          )}
        </Stack>
        <Slider {...cardSettings}>
          {session.slice(0, 10)?.map((item, idx) => (
            <CardPrimary
              key={idx + "UKddC"}
              auth={true}
              title={item.sn}
              img={
                item.sid &&
                `https://${
                  config.aws_cloudfront_url
                }/${config.aws_org_id.toLowerCase()}-resources/images/session-images/${
                  item.sid
                }.png`
              }
              addToBookMarkList={() => {}}
              addToFavorite={item.EI}
              sessionType={item.stype}
              sessionDate={moment(parseInt(item.startdate)).format(
                "DD MMM YYYY"
              )}
              handleLocation={() => {
                history.push(`/session?sid=${item.sid}`);
                dispatch(session(item));
              }}
            />
          ))}
        </Slider>
      </Box>
    );

  const allCourses = myCourses !== undefined &&
    myCourses.length > 0 &&
    userDetails && (
      <Box sx={{ m: { xs: 1, sm: 2, md: 3 } }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <HeadingPrimary>E-learning courses </HeadingPrimary>
          {myCourses.length > 10 && (
            <CusLink
              to="/viewall"
              onClick={() => {
                dispatch(
                  sessionAll({
                    type: "course",
                    header: "E-learning courses",
                    data: myCourses,
                  })
                );
                history.push("/viewall");
              }}
            >
              View all
            </CusLink>
          )}
        </Stack>

        <Slider {...cardSecondarySettings}>
          {myCourses.slice(0, 10)?.map((item, idx) => (
            <CardSecondary
              key={idx + "sdlfkshd"}
              img={
                item?.tid &&
                `https://${
                  config.aws_cloudfront_url
                }/${config.aws_org_id.toLowerCase()}-resources/images/topic-images/${
                  item?.tid
                }.png`
              }
              title={item?.ttitle}
              sessionDate={
                item.startdate &&
                moment(parseInt(item.startdate)).format("DD MMM YYYY")
              }
              handleLocation={() => {
                history.push(`/course?id=${item?.tid}`);
                dispatch(session({ ...item }));
              }}
              nonug={item?.noofnuggets}
            />
          ))}
        </Slider>
      </Box>
    );

  const breadcrumbs = [
    <Typography key="3" color={Theme.color.text}>
      Search
    </Typography>,
  ];
  return (
    <Wrapper>
      <Stack spacing={2} margin={{ xs: 1, sm: 2, md: 3 }}>
        {/* <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
            sx={{ color: Theme.color.text }}
          >
            {breadcrumbs}
          </Breadcrumbs> */}
        {/* <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <HeadingPrimary>Search</HeadingPrimary>
            <Stack direction="row" spacing={3}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Box>Sort By:</Box>
                <Box sx={{ width: 100 }}>
                  <Input />
                </Box>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Box>Filter By:</Box>
                <Box sx={{ width: 100 }}>
                  <Input />
                </Box>
              </Stack>
            </Stack>
          </Stack> */}

        {!loading && mSession}
        {!loading && allCourses}

        {!loading && session?.length <= 0 && myCourses?.length <= 0 && (
          <div
            style={{
              height: "60vh",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <img src={emptysearch} alt="emptysearch" />
            {/* <h3>Nothing here yet!</h3> */}
          </div>
        )}
        <Backdrop open={loading} sx={{ zIndex: "theme.drawer" + 1 }}>
          <CircularProgress />
        </Backdrop>
      </Stack>
    </Wrapper>
  );
};

export default Search;
