import { Box } from "@mui/system";
import React, { useContext, useState } from "react";

import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { Alert, CircularProgress, Snackbar, Typography } from "@mui/material";
import { CustomButton } from "../CustomButton/CustomButton";
import {
  inputBoxStyle,
  inputIconStyle,
  displayCenter,
  SignInAndSignUpValues,
} from "./SignInAndSignUp";

import "./phoneInput.styles.css";
import CustomInput from "../CustomInput/CustomInput";
// import "react-phone-number-input/style.css";

import { useForm } from "../../Hooks/useForm";
import { Theme } from "../../config/aws-exports";
import Auth from "@aws-amplify/auth";
import validateSignUp from "./SignInSignUpValidationRules/SignUpValidationRules";
import { HeadingPrimary } from "../Styles/Heading";
import cognitoValidation from "./SignInSignUpValidationRules/SignInCognitoValidationRules";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import Swal from "sweetalert2";
import { API } from "aws-amplify";
import config from "../../config/aws-exports";
const SignUp = () => {
  const { setSignUp, setSignIn } = useContext(SignInAndSignUpValues);

  const [errors, setErrors] = useState({});

  const [signUpBtnLoader, setSignUpBtnLoader] = useState(false);
  const {
    values,
    handleChange,
    setValues,
    handleClickShowPassword,
    handleMouseDownPassword,
  } = useForm({ showPassword: false });

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (Object.keys(validateSignUp(values)).length !== 0) {
      setErrors(validateSignUp(values));
      return;
    } else {
      const { name, lname, signUpPassword, email } = values;
      setSignUpBtnLoader(true);
      try {
        const response = await Auth.signUp({
          username: email.toLowerCase(),
          password: signUpPassword,
          attributes: {
            email, // optional
            // phone_number: values.phoneNumber, // optional - E.164 number convention
            // other custom attributes
            name: name + " " + lname,
            locale: "TenantOne",
          },
        });
        if (response) {
          console.log(response);
          let body = {
            oid: config.aws_org_id,
            tenant: config.tenant,
            type: "signup",
            eid: response.userSub,
            emailid: email,
            gen: 0,
            first_name: name,
            last_name: lname,
            dev: 2,
            st_id: config.stid,
            cno: values.phoneNumber,
            designation: values.designation,
            department: values.department,
            organization: values.organization,
            urole: 'User',
            scheme: config.schema,
            password: values.signUpPassword
          };
          console.log("body==", body);
          addUser(body);
          Swal.fire({
            title: "Successfully signed Up!",
            text: "You will be able to sign in now!",
            icon: "success",
            confirmButtonColor: Theme.color.primary,
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              setSignUp(false);
              setSignIn(true);
              setSignUpBtnLoader(false);
            }
          });
        }
        setSignUpBtnLoader(false);
      } catch (error) {
        console.log("error signing up:", error);
        setErrors(cognitoValidation(error));
        setSignUpBtnLoader(false);
      }
    }
  };

  const addUser = async (user) => {
    const bodyParam = {
      body: user,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      await API.post(config.aws_cloud_logic_custom_name, "/addUser", bodyParam);
    } catch (err) {
      console.log("error adding user:", err);
    }
  };
  return (
    <React.Fragment>
      <Box sx={{ textAlign: "center" }}>
        <HeadingPrimary>Create an account</HeadingPrimary>
      </Box>

      <form
        onSubmit={handleSubmit}
        noValidate
        autoComplete="off"
        style={displayCenter}
      >
        <React.Fragment>
          <Box component="div" sx={inputBoxStyle}>
            <CustomInput
              id="input-Uname"
              placeholder="First Name"
              name="name"
              value={values.name || ""}
              error={errors.name}
              type="text"
              onChange={handleChange("name")}
            >
              <PersonOutlineOutlinedIcon sx={inputIconStyle} />
            </CustomInput>
          </Box>
          <Box component="div" sx={inputBoxStyle}>
            <CustomInput
              id="input-Uname"
              placeholder="Last Name"
              name="name"
              value={values.lname || ""}
              error={errors.lname}
              type="text"
              onChange={handleChange("lname")}
            >
              <PersonOutlineOutlinedIcon sx={inputIconStyle} />
            </CustomInput>
          </Box>
          <Box component="div" sx={inputBoxStyle}>
            <CustomInput
              id="input-Uname"
              placeholder="Designation"
              name="designation"
              value={values.designation || ""}
              error={errors.designation}
              type="text"
              onChange={handleChange("designation")}
            >
              <PersonOutlineOutlinedIcon sx={inputIconStyle} />
            </CustomInput>
          </Box>
          <Box component="div" sx={inputBoxStyle}>
            <CustomInput
              id="input-Uname"
              placeholder="Department"
              name="department"
              value={values.department || ""}
              error={errors.department}
              type="text"
              onChange={handleChange("department")}
            >
              <PersonOutlineOutlinedIcon sx={inputIconStyle} />
            </CustomInput>
          </Box>
          <Box component="div" sx={inputBoxStyle}>
            <CustomInput
              id="input-Uname"
              placeholder="Organization"
              name="organization"
              value={values.organization || ""}
              error={errors.organization}
              type="text"
              onChange={handleChange("organization")}
            >
              <PersonOutlineOutlinedIcon sx={inputIconStyle} />
            </CustomInput>
          </Box>
          {/* <Box component="div" sx={inputBoxStyle}>
            <CustomInput
              id="input-Uname"
              placeholder="Last Name"
              name="name"
              value={values.lname || ""}
              error={errors.lname}
              type="text"
              onChange={handleChange("lname")}
            >
              <PersonOutlineOutlinedIcon sx={inputIconStyle} />
            </CustomInput>
          </Box> */}
          <Box component="div" sx={inputBoxStyle}>
            <CustomInput
              id="input-phone"
              placeholder="Enter phone number"
              name="phoneNumber"
              value={values.phoneNumber || ""}
              error={errors.phoneNumber}
              type="number"
              onChange={handleChange("phoneNumber")}
            >
              <PhoneIphoneIcon sx={inputIconStyle} />
            </CustomInput>
            {/* <PhoneInput
              defaultCountry="IN"
              className="PhoneInput"
              name="phone"
              style={errors.phoneNumber && { borderBottomColor: "#D32f2f" }}
              placeholder="Enter phone number"
              value={values.phone_number || ""}
              onChange={(value) =>
                setValues((values) => ({ ...values, phone_number: value }))
              }
            />
            {
              <p
                style={{
                  color: "#D32f2f",
                  fontSize: "14px",
                  fontFamily: "Roboto ",
                  marginTop: ".5rem",
                }}
              >
                {errors.phoneNumber}
              </p>
            } */}
          </Box>
          <Box component="div" sx={inputBoxStyle}>
            <CustomInput
              id="input-name-1"
              placeholder="Email"
              name="email"
              type="email"
              value={values.email || ""}
              error={errors.email}
              onChange={handleChange("email")}
            >
              <EmailOutlinedIcon sx={inputIconStyle} />
            </CustomInput>
          </Box>
          <Box component="div" sx={inputBoxStyle}>
            <CustomInput
              id="input-signUpPassword"
              placeholder="Password"
              type="password"
              name="signUpPassword"
              inputValues={values}
              value={values.signUpPassword || ""}
              error={errors.password}
              handleClickShowPassword={handleClickShowPassword}
              handleMouseDownPassword={handleMouseDownPassword}
              onChange={handleChange("signUpPassword")}
            >
              <LockOutlinedIcon sx={inputIconStyle} />
            </CustomInput>
          </Box>

          <Box component="div" sx={{ textAlign: "center", mb: 3 }}>
            {signUpBtnLoader ? (
              <CustomButton disabled loading="true">
                <CircularProgress color="inherit" size={20} />
              </CustomButton>
            ) : (
              <CustomButton primary type="submit">
                SignUp
              </CustomButton>
            )}
          </Box>
          <Typography
            onClick={() => {
              setSignIn(true);
              setSignUp(false);
            }}
            variant="caption"
            display="block"
            gutterBottom
            sx={{ fontSize: "1rem", textAlign: "center", cursor: "pointer" }}
          >
            Already have an account?
            <span
              style={{
                textDecoration: "underline",
                color: Theme.color.secondary,
                fontWeight: "bold",
              }}
            >
              Login here
            </span>
          </Typography>
        </React.Fragment>
      </form>
    </React.Fragment>
  );
};

export default SignUp;
